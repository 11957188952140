export default function buildBadgesMarkup(badgesData, config) {
    let badgesMarkUp = ''
    if(badgesData && config.isShowingBadges == 'true'){
        badgesData.forEach(badge => {
            const iconPath      = badge.icon.replace('http://localhost/','http://localhost:8080/');
            let iconPosition    = badge.badgeIconPosition == "" ? "left" : badge.badgeIconPosition;
            const textPosition  = iconPosition == "left" ? 'order-1' : 'order-2';
            iconPosition        = iconPath == "left" ? 'order-2 ml-[8px]' : 'order-1 mr-[8px]';

            const fontWeightClass = badge.fontWeight == "" ? "font-[100]" : `font-[${badge.fontWeight}]`
            
            let badgePositionClass = 'left-0 top-0'
            switch (badge.badgePosition) {
                case 'tr':
                    badgePositionClass = 'right-0 top-0'
                    break;
                case 'br':
                    badgePositionClass = 'right-0 bottom-0'
                    break;
                case 'bl':
                    badgePositionClass = 'left-0 bottom-0'
                    break;
                default:
                    break;
            }

            badgesMarkUp = `
                ${badgesMarkUp}
                <div class="absolute z-10 p-[10px] flex justify-between ${badgePositionClass}">    
                    <div class="badge bg-${badge.badgeBackground} py-[5px] px-[10px] rounded-[32px] flex items-center">
                        <img src="${iconPath}" class="w-4 h-4 lazyload ${iconPosition}">
                        <span class="${badge.badgeFontSize} text-${badge.badgeTextColor} ${fontWeightClass} ${textPosition} leading-5">
                            ${badge.label}
                        </span>
                    </div>
                </div>
            `
        });
    }

    return badgesMarkUp
}
