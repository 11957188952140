import { pushVideoEvent, VIDEO } from "../events";

// Youtube
export function embedYtScript(ytVideo, config) {
    let ytIframeAPI = "https://www.youtube.com/iframe_api";
    if (typeof window.YT == "undefined" || typeof window.YT.Player == "undefined") {
        let tag = document.createElement("script");
        tag.src = ytIframeAPI;
        let firstScriptTag = document.querySelector("script");
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        if (Array.isArray(window.ytIframes)) {
            window.ytIframes.push({ ytVideo, config });
        } else {
            window.ytIframes = [{ ytVideo, config }];
        }

        window.onYouTubePlayerAPIReady = function () {
            window.ytIframes.forEach(ytIframe => onYouTubeReady(ytIframe.ytVideo, ytIframe.config));
        };
    } else {
        onYouTubeReady(ytVideo, config);
    }
}

export function onYouTubeReady(ytVideo, config) {
    let id = ytVideo.getAttribute("id");
    let videoId = ytVideo.dataset.videoid;
    let title = ytVideo.dataset.title;
    let context = ytVideo.dataset.gtmcontext;
    let carousel = ytVideo.closest(".carousel__wrap");
    let eventsPushed = {};
    let VIDEO_DURATION = null;
    let timer = null;

    let player = new window.YT.Player(id, {
        videoId: videoId,
        title: title,
        playerVars: {
            playsinline: 1,
            autoplay: config.autoplay,
        },
        events: {
            onStateChange: onPlayerStateChange,
            onError: onPlayerError,
        },
    });

    player.addEventListener("onReady", (e) => {
        if (e.target.playerInfo.duration === 0) {
            pushVideoEvent({
                event: VIDEO.error,
                name: e.target.playerInfo.videoData.title,
                error_type: "video not found",
                context: context,
            });
            eventsPushed[VIDEO.error] = true;
        } else {
            if (ytVideo.closest(".tns-slide-cloned")) {  // to avoid duplicate "view" push, due to carousel cloning of slides
                eventsPushed[VIDEO.view] = true;
            } else {
                if (!eventsPushed[VIDEO.view]) {
                    pushVideoEvent({
                        event: VIDEO.view,
                        name: e.target.playerInfo.videoData.title,
                        length: e.target.playerInfo.duration,
                    });
                    eventsPushed[VIDEO.view] = true;
                }
            }
        }
    });

    function handlePercentWatched(event) {
        if (player && player.getCurrentTime) {
            let videoTime = player.getCurrentTime();
            let videoTitle = event.target.playerInfo?.videoData?.title == "" ? title : event.target.playerInfo.videoData.title;

            //video duration changes on replay for some videos and never allows video_progress_100%
            //capturing the initial player duration remedies this
            if (!VIDEO_DURATION) {
                VIDEO_DURATION = player.getDuration();
            }

            let pct = (videoTime / VIDEO_DURATION) * 100;
            let pctCeil = Math.ceil(pct);
            if (pctCeil >= 10 && pctCeil % 10 === 0) {
                if (!eventsPushed[pctCeil]) {
                    eventsPushed[pctCeil] = true;
                    pushVideoEvent({
                        event: VIDEO.progress(pctCeil),
                        name: videoTitle,
                        length: VIDEO_DURATION,
                        context: context,
                    });
                }
            }
        }
    }

    function onPlayerStateChange(event) {
        /*
         event.target.playerInfo.playerState
         0 = ended
         1 = playing
       */

        if (event.target.playerInfo.playerState === window.YT.PlayerState.PLAYING) {
            if (!eventsPushed[VIDEO.start]) {
                pushVideoEvent({
                    event: VIDEO.start,
                    name: event.target.playerInfo.videoData.title,
                    length: event.target.playerInfo.duration,
                    context: context,
                });

                eventsPushed[VIDEO.start] = true;
            }

            if (timer) {
                clearInterval(timer);
            }

            timer = setInterval(handlePercentWatched, 100, event);
            if (carousel) {
                const carouselId = carousel.dataset.uuid;
                const tnsCarousel = window.tnsCarousels.find((x) => x.uuid == carouselId);
                if (tnsCarousel) {
                    tnsCarousel.carousel.pause();
                }
            }
        } else if (event.data === 0 || event.target.playerInfo.playerState === window.YT.PlayerState.ENDED) {
            //clear evnets pushed in case user wants to replay
            eventsPushed = {};

            pushVideoEvent({
                event: VIDEO.finish,
                name: event.target.playerInfo.videoData.title,
                length: event.target.playerInfo.duration,
                context: context,
            });

            clearInterval(timer);
        } else if (event.target.playerInfo.playerState === window.YT.PlayerState.PAUSED) {
            clearInterval(timer);
        }
    }

    function onPlayerError(event) {
        if (timer) {
            clearInterval(timer);
        }

        let videoTitle = event.target.playerInfo.videoData.title == "" ? title : event.target.playerInfo.videoData.title;

        if (!eventsPushed[VIDEO.error]) {
            pushVideoEvent({
                event: VIDEO.error,
                name: videoTitle,
                error_type: getErrorTypes(event.data),
                context: context,
            });

            eventsPushed[VIDEO.error] = true;
        }
    }

    function getErrorTypes(errorNo) {
        let errorType = "an error occurred";
        switch (errorNo) {
            case 2: // The request contains an invalid parameter value. For example, this error occurs if you specify a video ID that does not have 11 characters, or if the video ID contains invalid characters, such as exclamation points or asterisks.
                errorType = "invalid video ID";
                break;
            case 5: // The requested content cannot be played in an HTML5 player or another error related to the HTML5 player has occurred.
                errorType = "browser does not support video format";
                break;
            case 100: // The video requested was not found. This error occurs when a video has been removed (for any reason) or has been marked as private.
                errorType = "video not found";
                break;
            case 101: // The owner of the requested video does not allow it to be played in embedded players.
            case 150: // This error is the same as 101. It's just a 101 error in disguise!
                errorType = "restricted video";
                break;
            default:
        }
        return errorType;
    }
}
