import buildBadgesMarkup from "./algoProductCardBadges";
import builMediaMarkUp from "./algoCardMedia";
import buildCategoriesMarkUp from "./algoProductCategories";
import buildBVMarkUp from "./algoProductCardBazaarVoice";
import buildWtbButtonMarkUp from "./algoProductCardWtbButton";

export default function buildProductCard(item, instantsearch, config) {
    const learnMoreIconPositionClass = config.learnMoreIconPosition == "left" ? "order-1" : "order-2";

    const categoriesMarkup = buildCategoriesMarkUp(item, config, false);
    const mediaMarkUp = builMediaMarkUp(item, config);
    const bvRating = buildBVMarkUp(item, config);
    const wtbButtonMarkup = buildWtbButtonMarkUp(item, config);
    const badgesMarkUp = buildBadgesMarkup(item.badgesData, config);
    const listItemClass = config.showInfiniteHits == "true" ? "" : "opacity-0";
    const imgContainerClass = config.showAnimatedMedia == "true" && item.animatedAsset ? "" : "p-4";

    let styleAttr = ``;
    if (config.borderRadius) {
        styleAttr = `style="border-radius: ${config.borderRadius}${config.borderRadiusUnit}"`;
    }

    let learnMoreIconMarkUp = "";
    if (config.learnMoreIconPath != "") {
        learnMoreIconMarkUp = `<div class="w-6 mx-2 ${learnMoreIconPositionClass}"> 
            <img src="${config.learnMoreIconPath}" alt="${config.learnMoreLabel} icon" class="w-5 h-5" />
        </div>`;
    }

    let descriptionMarkUp = "";
    if (config.showItemDescription == "true") {
        descriptionMarkUp = `<div class="font-body rich-text mt-2 mb-2 ${config.summaryFontSize} text-${config.summaryFontColor}">
            <p>${item.description}</p>
        </div>`;
    }

    return `
    <li
        class="card--default card bg-${config.backgroundColor} h-full overflow-hidden flex flex-col text-default border border-solid border-${config.borderColor} shadow-sm hover:shadow-lg relative transition-all ${listItemClass}"
        ${styleAttr}
    >
        <div
            class="bg-${config.imageBackgroundColor} card__image-container w-full flex-none relative text-white card__actualRatio ${imgContainerClass}"
            ${styleAttr}
        >
            <a
                href="${item.url}"
                class="transition-all"
                rel="nofollow"
            >
                ${badgesMarkUp}
                <div class="uiImage relative block w-full card__image min-h-[318px]">
                    ${mediaMarkUp}
                </div>
            </a>
        </div>

        <div class="card__content w-full z-10 flex flex-col flex-grow p-7">
            <a href="${item.url}">
                <h3 class="font-card text-${config.titleFontColor} ${config.titleFontSize}">
                    ${item.title}
                </h3>
            </a>
            ${bvRating}
            <div class="mb-4">
                <ul class="flex flex-wrap font-body transition-all">
                    ${categoriesMarkup}
                </ul>
            </div>
            
            ${descriptionMarkUp}

            <div id="spacer" class="flex-grow"></div>
            <div class="card__actions ${config.buttonLayout == "row" ? "flex flex-row space-x-2" : ""}" x-data="">
                <div class="w-${config.ctaOneWidth} block mb-2 mt-3 text-center">
                    <div class="h-full">
                        <a
                        class="!flex button button--${config.cardButtonType} font-title overflow-hidden border rounded-full border-solid text-center button--full flex flex-row h-full justify-center items-center"
                        href="${item.url}"
                        >
                            ${learnMoreIconMarkUp}
                            <span class="${learnMoreIconPositionClass == "order-1" ? "order-2" : "order-1"}">
                                ${config.learnMoreLabel}
                            </span>
                        </a>
                    </div>
                </div>
                ${wtbButtonMarkup}
            </div>
        </div>
    </li>
    `;
}
