export default function buildRefinementList(items, container, config, groupArrays) {
    // TODO Do we need these all?
    const gtmContext = "";
    const uuid = "UUIID_here";
    const accordionsUUID = "accordionsUUID---1";
    const activeClasses = "";
    const colorClasses = "";
    const clickEvent = "";

    // If there is no groupArray from CMS show all possible categories
    if (groupArrays.length < 1 && items.length > 0) {
        groupArrays = [
            {
                title: "Miscellaneous", //TODO i18n or Managed
                activeCount: 0,
                includedCategories: items.map((item) => item.value),
                includedCategoriesActiveState: items.map((item) => {
                    return {
                        title: item.value,
                        isActive: false,
                    };
                }),
            },
        ];
        config.facetsArray = groupArrays;
    }

    groupArrays.forEach((group, i) => {
        const intersection = matchInArrays(items, group.includedCategories);
        const groupActiveCount = config.hasSetFacetArrays ? `config.facetsArray[${i}].activeCount` : "config.checkedFacetCount";

        if (intersection.length > 0) {
            container.innerHTML = `
                ${container.innerHTML.length > 0 ? container.innerHTML : ""}
                <div
                    x-data="accordion({ 
                    open: ${isAccordionOpen(config, i)},
                    title: '${group.title}',
                    gtmContext: '${gtmContext}',})"
                    @accordions-toggle.window="
                    if($event.detail.targetID == '${accordionsUUID}') {
                        toggle($event.detail.action)
                    }
                    "
                    class="accordion-chc accordionContainer border rounded-xl mt-4 p-4">
                        <button
                            aria-controls="accordion__content--${uuid}"
                            class="accordionButton block w-full py-1 text-left text-xl"
                            :class="{'${activeClasses}': open, '${colorClasses} ': !open}"
                            :aria-expanded="open ? 'true' : 'false'"
                            @click="toggle(!open)${clickEvent}">
                            
                            <div class="flex items-center w-full justify-between">
                                <div class="flex flex-row text-2xl text-primary1 items-center justify-between w-full">
                                    <div class="flex flex-row items-center">
                                        ${group.title} 
                                        <div x-show="${groupActiveCount} > 0" x-text="${groupActiveCount}" class="ml-4 mr-2 rounded-full bg-primary1 text-primary3 w-6 h-6 text-sm flex items-center justify-center group-hover:bg-primary3 group-hover:text-primary1"></div>
                                    </div>
                                    <div class="iconPlusMinus">
                                        <svg class="w-6 h-6 inline mx-1 fill-current" aria-hidden="true" role="img" x-show="!open">
                                            <use xlink:href="${config.contextPath}/.resources/chc-lm-platform/themes/sanofi-platform/src/img/sprites/global.svg#chevron-down"></use>
                                        </svg>
                                        <svg class="w-6 h-6 inline mx-1 fill-current" aria-hidden="true" role="img" x-show="open">
                                            <use xlink:href="${config.contextPath}/.resources/chc-lm-platform/themes/sanofi-platform/src/img/sprites/global.svg#chevron-up"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </button>
                        <div
                            id="accordion__content--${uuid}"
                            class="accordion__content relative overflow-hidden transition-all duration-500"
                            :class="open ? 'block' : 'hidden'"
                            x-ref="accordion_${uuid}">
                            <div class="bg-transparent">
                                ${intersection
                                    .map(
                                        (item) => `
                                        <div class="flex p-1 leading-3 refineItem filterItems__item cursor-pointer px-1 ${item.isRefined ? "isChecked" : ""}">
                                            
                                            <div class="flex w-full items-center gap-2.5 py-2">
                                                <input 
                                                    type="checkbox" 
                                                    id="${item.value}" 
                                                    value="${item.value}" 
                                                    data-value="${item.value}" 
                                                    data-is-refined="${item.isRefined}"
                                                    data-filter-group="${i}"
                                                    ${item.isRefined ? "checked" : ""}
                                                    class="
                                                        peer 
                                                        relative 
                                                        left-0 
                                                        shrink-0 
                                                        appearance-none 
                                                        h-8
                                                        w-10
                                                        rounded-md
                                                        after:rounded
                                                        border-2
                                                        border-primary1
                                                        after:absolute 
                                                        after:left-0 
                                                        after:top-0 
                                                        after:h-full
                                                        after:w-full 
                                                        after:content-[''] 
                                                        after:bg-white
                                                        cursor-pointer
                                                        hover:ring 
                                                        hover:after:ring-primary1
                                                        transition-all
                                                    "
                                                    />
                                            
                                                <label 
                                                    for="${item.value}" 
                                                    class="
                                                        inline-block
                                                        w-full
                                                        cursor-pointer
                                                        text-xl
                                                        text-primary1
                                                        
                                                        "
                                                    >
                                                    ${item.label}
                                                </label>
                                            </div>
                                        </div>
                                        `
                                    )
                                    .join("")}
                            </div>
                        </div>
                    </div>
                
            `;
        }
    });

    return `
        group
    `;
}
function matchInArrays(arr1, arr2) {
    const matches = arr1.filter((element) => {
        if (arr2.includes(element.value)) {
            return element;
        }
    });
    return matches;
}

function isAccordionOpen(config, i) {
    let isOpen = true;
    if (config.facetsAccordionOpen !== "all") {
        isOpen = i > 0 ? false : true;
    }
    return isOpen;
}
