import chcBasePage from "../modules/chcBasePage";

class chcDefaultPage extends chcBasePage {
    
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
    }
}

new chcDefaultPage();