import themeScreens from "../../../tailwind/theme.screens";
// Convert the tailwind screens object into array
const breakpointsFromTailwindCss = () => {
    // TODO: make this dynamic for
    return Object.entries(themeScreens).map((breakpoint) => {
        const key = breakpoint[0];
        const val = Number(breakpoint[1].replace(/px/g, ""));
        return { key, val };
    });
};

const breakpoints = breakpointsFromTailwindCss();

/**
 * currentBreakpoint
 * @returns tailwind breakpoint key (sm,md,lg...)
 */
export const currentBreakpoint = () => {
    const windowWidth = window.innerWidth;

    // Change to strings to numbers
    if (isNaN(themeScreens["xs"])) {
        for (const screensize in themeScreens) {
            themeScreens[screensize] = Number(themeScreens[screensize].replace(/px/g, ""));
        }
    }

    let current = "default";
    if (windowWidth < themeScreens["sm"]) {
        return "xs";
    }
    if (windowWidth > themeScreens["xs"] && windowWidth < themeScreens["md"]) {
        return "sm";
    }
    if (windowWidth > themeScreens["md"] && windowWidth < themeScreens["lg"]) {
        return "md";
    }
    if (windowWidth > themeScreens["lg"] && windowWidth < themeScreens["xl"]) {
        return "lg";
    }
    if (windowWidth > themeScreens["lg"]) {
        return "xl";
    }
    return current;
};

/**
 *
 * @param {Tailwind breakpoint key} key "sm,md,lg..."
 * @returns Boolean
 */
export const breakpointIs = (key) => {
    const windowWidth = window.innerWidth;
    const pointVal = breakpoints.find((point) => point.key == key);

    if (!pointVal) {
        return false;
    }

    if (windowWidth >= pointVal.val) {
        return true;
    }
    return false;
};
