export default function buildWtbButtonMarkUp(item, config) {
    const iconPositionClass = config.whereToBuyIconPosition == 'left' ? 'order-1' : 'order-2'
    let wtbButtonMarkup = ``
    
    let iconMarkUp = '';
    if(config.whereToBuyIconPath){
        iconMarkUp = `<div class="w-6 mx-2 ${iconPositionClass}"> 
            <img src="${config.whereToBuyIconPath}" alt="${config.whereToBuyLabel} icon" class="w-5 h-5" />
        </div>`;
    }

    if(config.isShowingWhereToBuy == 'true'){
        wtbButtonMarkup = `
        <div class="block mb-2 mt-3 text-center w-${config.ctaTwoWidth}">
            <div class="h-full">
                <a
                    class="!flex button button--${config.cardButtonTypeWtb} font-title overflow-hidden border rounded-full border-solid text-center button--full flex-row h-full justify-center items-center"
                    href="${item.url}#ps-widget"
                >
                    

                    
                    ${iconMarkUp}
                    <span class="${iconPositionClass == 'order-1' ? 'order-2': 'order-1'}">
                        ${config.whereToBuyLabel}
                    </span>

                </a>
            </div>
        </div>
        `
    }

    return wtbButtonMarkup
}