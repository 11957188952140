import whenDomReady from "when-dom-ready";
/* eslint-disable */

document.addEventListener("alpine:init", () => {
    Alpine.data("chcHeader", (config) => ({
        ...config,
        showHeader: true,
        prevScrollPos: 0,
        scrollHide: false,
        mouseoutside: false,
        timeout: null,
        mobileOpen: false,
        targetPercentThrew: 90, // Used for Bg color change
        init() {
            this.showHeader = true;
            this.prevScrollPos = window.scrollY;
            this.handleStickyNavOverlaySpacer();

            if (this.secondBgColorClass) {
                this.targetHeroEl = document.querySelector(".hero-wrap");
                this.scrollable = this.targetHeroEl.clientHeight;
                this.handleScrollBgColor(window.scrollY);
            }
        },
        handleStickyNavOverlaySpacer() {
            const stickyNavOverlaySpacer = document.querySelector(".sticky-nav-overlay-spacer");

            if (stickyNavOverlaySpacer) {
                const header = document.querySelector("header");
                stickyNavOverlaySpacer.style.height = `${header.offsetHeight}px`;
            }
        },
        scrollStart(scrollAction) {
            whenDomReady(() => {
                if (this.mobileOpen) return false;

                const scrolled = window.scrollY;
                if (this.secondBgColorClass != "" && this.firstBgColorClass) {
                    this.handleScrollBgColor(scrolled);
                    this.handleScrollVisibility(scrolled);
                } else {
                    this.handleScrollVisibility(scrolled);
                }
            });
        },
        handleScrollVisibility(scrolled) {
            clearTimeout(this.timeout);
            if (scrolled > 10) {
                this.showHeader = !(scrolled > this.prevScrollPos);
            } else {
                this.showHeader = true;
            }

            if (document.querySelector(".fancybox__container")) this.showHeader = true;

            if (this.$store.chcHeader.modalShow || this.mouseoutside) this.showHeader = true;

            this.timeout = setTimeout(() => {
                this.prevScrollPos = scrolled;
            }, 300);
        },
        handleScrollBgColor(scrolled) {
            const scrollPercentage = (scrolled / this.scrollable) * 100;

            if (scrollPercentage >= this.targetPercentThrew) {
                this.$el.classList.remove(this.firstBgColorClass);
                this.$el.classList.add(this.secondBgColorClass);
            } else {
                this.$el.classList.remove(this.secondBgColorClass);
                this.$el.classList.add(this.firstBgColorClass);
            }
        },
        scrollEnd() {
            this.prevScrollPos = window.scrollY;
        },
        trackMouse(e) {
            if (e.movementY < -5 || e.movementY > 5) return false;

            const positionY = e.clientY;
            const announcementBar = document.querySelector(".announcementBar");
            const utilityBar = document.querySelector(".utility-links");
            const announcementBarHeight = announcementBar ? announcementBar.clientHeight : 0;
            const utilityBarHeight = utilityBar ? utilityBar.clientHeight : 0;
            const navPos = announcementBarHeight + utilityBarHeight + 30;

            if (positionY < navPos && !this.mouseoutside) {
                this.showHeader = true;
                this.prevScrollPos = window.scrollY;
            }
        },
        mouseOut(e) {
            const positionY = e.clientY;
            this.mouseoutside = positionY < 0 ? true : false;
        },
        deactivateScroll(e) {
            if (e.target.closest(".modal-chc")) {
                if (!this.$store.chcHeader.modalShow) {
                    window.scrollTo({
                        top: this.prevScrollPos,
                    });
                }
            }

            if (this.$store.chcHeader.modalShow) this.showHeader = true;
        },
        bodyScrollHide(bool) {
            this.mobileOpen = bool;
            document.body.style.overflow = bool ? "hidden" : "auto";
        },
    }));

    Alpine.store("chcHeader", {
        modalShow: false,
        openModalMobile: false, // for opening the country modal
    });
});
