export const removeUrlLastSlash = () => {
    Array.prototype.slice.call(document.querySelectorAll("a"))
        .filter(el => location.origin === el.origin && (/\/$/).test(el.href))
        .forEach(el => el.href = el.href.replace(/\/$/, ""))
}

export const checkStickyHeight = () => {
    const sticky = window.innerWidth > 1025 ? "lg\\:sticky" : "sticky";
    const addTop = window.innerWidth > 1025 ? 0 : 2;
    const endorsement = document.querySelector(`.endorsementBar.${sticky}`);
    const announcement = document.querySelector(".announcementBar.sticky");
    const header = document.querySelector("header.sticky");

    if (endorsement && announcement) {
        const endorsementHeight = endorsement.clientHeight;
        const announcementHeight = announcement.clientHeight;
        const sumHeight = endorsementHeight + announcementHeight + addTop;
        header.style.top = `${sumHeight}px`;
    }
}

export const announcementFunc = () => {
    checkStickyHeight();
}

export const scrollToPos = (id) => {   
    const elId = id.indexOf("#") !== -1? id : `#${id}`;
    const elem = document.querySelector(`${decodeURI(elId)}`);
    const headerHeight = document.querySelector("header")?.clientHeight || 0;
    const announcementHeight = document.querySelector(".announcementBar")?.clientHeight || 0;
    const currPosition = window.scrollY;
    
    if (elem) {
        const minusElem = headerHeight + announcementHeight;
        const top = (elem.getBoundingClientRect().top + currPosition) - minusElem;
        window.scrollTo({
            top,
            behavior: "smooth"
        });
    }
}

export const anchorScrollToFunc = () => {
    document.querySelectorAll("a").forEach(anchor => {
        const hash = anchor.hash;
        const pathname = anchor.pathname;
        const pagePathname = window.location.pathname;

        if (hash) {
            anchor.onclick = (e) => {
                if (pathname === pagePathname) {
                    e.preventDefault();
                    scrollToPos(hash);
                }
            }
        }
    })
}

export const YTVidAutoPause = vidIframe => {
    if (!vidIframe) return;
            
    vidIframe.forEach(slide => {
        const YTiFrame = slide.querySelector(".youtube-iframe");
        if (YTiFrame && YTiFrame.tagName === "IFRAME") YTiFrame.contentWindow.postMessage('{"event":"command", "func":"pauseVideo", "args":""}', "*");
    });
}

export default {}
