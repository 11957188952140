/* eslint-disable */

import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import { Thumbs } from "@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js";
import { Autoplay } from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js";
import { YTVidAutoPause } from "./chc/helpers";

document.addEventListener("alpine:init", () => {

    Alpine.data("slider", (props) => {
        const state = {
            slider: null,
            ...props,
        };

        const sliderOptions = {
            Autoplay : {
                autoStart: state.isAutoplay,
                timeout : state.autoPlaySpeed + '000',
                showProgress: state.showProgress
            },
            infinite: true,
            Dots: false,
            Navigation: false,
            adaptiveHeight: true,
            Thumbs: {
                type: "classic",
                parentEl: document.getElementById(state.sliderId + '-thumbnails'),
                Carousel: {
                    axis: state.axis
                },
            },
            breakpoints: {
                "(max-width: 767px)": {
                    Thumbs: {
                        Carousel: {
                            axis: 'x'
                        }
                    }
                }
            }
        };

        return {
            ...state,

            init() {
                const _self = this.$el;
                const sliderElm = document.getElementById(state.sliderId);
                const onEvent = {
                    on: {
                        change: () => YTVidAutoPause(_self.querySelectorAll(".f-carousel__slide")),
                        ready: () => this.$refs.sliderContainer.classList.remove("hidden")
                    }
                }

                if (!state.isEditMode) {
                    state.slider = new Carousel(sliderElm, { ...sliderOptions, ...onEvent }, { Thumbs, Autoplay });
                }
            },
        };
    });

});