export default function buildCategoriesMarkUp(item, config, isArticle) {
    const currentUrl = new URL(window.location.href)
    const categories = item.categories;
    let categoriesMarkup = "";

    if(item.categories){
        categoriesMarkup = categories
        .map((category) => {
                if(!config.decodedCategories[category]) return;

                let defaultTagClass = 'category-tags';
                let defaultTagClassInner = 'card-tag';
                
                // Check if we need to add class for custom tag bg color
                config.facetsArray.forEach(facetGroup => {
                    const found = facetGroup.includedCategoriesActiveState.find((facet) => facet.title == category);
                    if (found) {
                        if (found.categoryCustomBgColor || found.categoryCustomBorderColor || found.categoryCustomFontColor) {
                            defaultTagClassInner = '';
                            defaultTagClass += ` category-tags__customStyle-${config.decodedCategories[category]}`
                        }
                    }
                });

                // Build url with slug
                const slug = config.decodedCategories[category] ? config.decodedCategories[category] : encodeURIComponent(category)
                let cleanedPathName = currentUrl.pathname;
                cleanedPathName = cleanedPathName.split(config.pagename)[0];
                cleanedPathName = `${cleanedPathName}${config.pagename}`;
                cleanedPathName = cleanedPathName.replace(/\/$/, "");
                const categoryUrl = `${cleanedPathName}/${slug}`;

                if (isArticle) {
                    return `<a
                            href="${categoryUrl}"
                            class=""
                        >
                            <div class="mr-2 mb-2 ${defaultTagClass} leading-[18px] rounded-2xl px-2 py-1 font-body border border-solid">
                                ${category}
                            </div> 
                        </a>`
                } else {
                    return `<li
                                style="margin: 9px 9px 0 0"
                                class=" ${defaultTagClass} normal-case font-normal not-italic text-0.9 hover:underline px-2 border border-solid transition-all border border-solid"
                            >
                                <a
                                    href="${categoryUrl}"
                                    class="${defaultTagClassInner} text-0.9"
                                    rel="nofollow"
                                >
                                    ${category}
                                </a>
                            </li>
                        `
                }
            })
            .join("");
    }

    return categoriesMarkup
}