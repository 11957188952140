export default function builMediaMarkUp(item, config, classes) {
    //TODO No image makes infinite scroll not blip
    const lazyClass = config.showInfiniteHits == "true" ? "" : "lazyload transition-all";
    const imageClass = classes ? classes : `w-full block ${config.imageFill} ${lazyClass}`
        
    // Fail safe image placeholder
    let mediaMarkUp = `<img src="${config.imagePlaceholderPath}" alt="${item.title}"  class="${imageClass}"/>`;
    
    if (item.image){
        mediaMarkUp = `<img src="${item.image}" alt="${item.title}" class="${imageClass}"/>`;
    }

    if (item.animatedAsset && config.showAnimatedMedia == "true") {
        const animatedMediaObj = item.animatedAsset;
        const assetLink = animatedMediaObj.link;
        const showControls = animatedMediaObj.showControls ? animatedMediaObj.showControls : "false"
        const isLoop = animatedMediaObj.isLoop ? animatedMediaObj.isLoop : "false"
        const isBounce = animatedMediaObj.isBounce ? animatedMediaObj.isBounce : "false"
        const isAutoplay = animatedMediaObj.isAutoplay ? animatedMediaObj.isAutoplay : "false"
        const animationSpeed = animatedMediaObj.animationSpeed ? animatedMediaObj.animationSpeed : "1"

        mediaMarkUp = `<lottie-player
        x-data="animatedMedia({
            showControls: ${showControls},
            isLoop: ${isLoop},
            isBounce: ${isBounce},
            isAutoplay: ${isAutoplay},
            animationSpeed: ${animationSpeed},
        })"
        x-ref="lottie"
        class="cursor-pointer"
        src="${assetLink}"
        x-intersect:enter.half="isMobile ? enterView : null"
        x-intersect:leave="isMobile ? exitView : null"
        :mode="isBounce ? 'bounce' : 'normal'"
        :speed="animationSpeed"
        :controls="showControls ? '' : null"
        :autoplay="isAutoplay && !isMobile ? '' : null"
        :hover="isMobile || isAutoplay ? null : ''"
        :loop="isLoop ? '' : null"
        >
        </lottie-player>`
    }

    return mediaMarkUp
}