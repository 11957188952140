/* eslint-disable */
import { scrollToPos } from "./helpers";


document.addEventListener("alpine:init", () => {

    Alpine.data("buttonAnchor", () => ({
        scrollToPos(id) {
            scrollToPos(id);
        }
    }));

});
