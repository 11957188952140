import { setPauseButton } from "..";
import { pushVideoEvent, VIDEO } from "../events";

// video.js
export function vjsPlayer(video) {
    const videoEl = video.el();
    let eventsPushed = {};
    setPauseButton(videoEl.parentElement);

    video.on("loadedmetadata", function (e) {
        if (!videoEl.closest(".tns-slide-cloned")) {  // to avoid duplicate "view" push, due to carousel cloning of slides
            pushVideoEvent({
                event: VIDEO.view,
                name: e.target.player.mediainfo.name,
                length: e.target.player.mediainfo.duration,
            });
        }
    });

    // Play
    video.on("play", function (e) {
        // track start event only one time https://snfimce.atlassian.net/browse/MPW-958
        if (!videoEl.dataset.hasPlayed) {
            pushVideoEvent({
                event: VIDEO.start,
                name: e.target.player.mediainfo.name,
                length: e.target.player.mediainfo.duration,
                context: e.target.dataset.gtmcontext,
            });
            videoEl.dataset.hasPlayed = true;
        }
    });

    video.on("timeupdate", function (e) {
        let pct = (e.target.player.currentTime() / e.target.player.mediainfo.duration) * 100;
        let pctFloor = Math.floor(pct);
        if (pct >= 10 && pctFloor % 10 === 0) {
            if (!eventsPushed[pctFloor]) {
                eventsPushed[pctFloor] = true;
                pushVideoEvent({
                    event: VIDEO.progress(pctFloor),
                    name: e.target.player.mediainfo.name,
                    length: e.target.player.mediainfo.duration,
                    context: e.target.dataset.gtmcontext,
                });
            }
        }
    });

    // Ended
    video.on("ended", function (e) {
        eventsPushed = {};
        pushVideoEvent({
            event: VIDEO.finish,
            name: e.target.player.mediainfo.name,
            length: e.target.player.mediainfo.duration,
            context: e.target.dataset.gtmcontext,
        });
    });

    // Error
    video.on("error", function (e) {
        if (!eventsPushed[VIDEO.error]) {
            pushVideoEvent({
                event: VIDEO.error,
                name: e.target.player.mediainfo.id,
                error_type: e.target.player.error().code,
                context: e.target.dataset.gtmcontext,
            });

            eventsPushed[VIDEO.error] = true;
        }
    });
}