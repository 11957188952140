/**
 * Default page javascript extended from sanofi-lm-platform.
 * All other pages in sanofi-lm-emr extend this one.
 */

import BasePage from "platform/src/js/modules/basePage";


class chcBasePage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
    }
}

export default chcBasePage;