/* eslint-disable */
import Alpine from "alpinejs";
import { animeJs, registerPresets } from "@crescendo-collective/anim-scroll";
import { currentBreakpoint } from "../../breakpoint";
import whenDomReady from "when-dom-ready";
import { anime } from 'animejs/lib/anime.es.js';

const dataFN = (config) => {
    return {
        initOnScroll: false,
        hasAnimatedEndingBubble: false,
        showButtomBubble: true,
        prevScrollPos: 0,
        timeout: null,
        revealEndingBubbleIsRunning: false,
        scrollDir: null,
        ...config,
        init() {
            this.setTranslateSpacing();
            this.scrollTranslateBg();
            this.listenForWindowResize();
        },
        scrollStart() {
            whenDomReady(() => {
                const scrolled = window.scrollY;
            });
        },
        listenForWindowResize() {
            const self = this;
            let isResizing;
            window.addEventListener("resize", function () {
                window.clearTimeout(isResizing);
                isResizing = setTimeout(function () {
                    self.currBp = currentBreakpoint();
                    self.setTranslateSpacing()
                }, 500);
            });
        },
        setTranslateSpacing(){
            this.currBp = currentBreakpoint();
            this.firstBubbleTranslate = `-${config.offsetSlideUpDsk}`;
            this.endBubbleTranslate = config.translateEndingBubbleDesktop
            if (this.currBp == 'sm' || this.currBp == 'xs'){
                this.firstBubbleTranslate = `-${config.offsetSlideUpMbl}`;
                this.openingBubbleStartTime = config.startTimeOpeningBubbleMobile;
                this.openingBubbleEndTime = config.endTimeOpeningBubbleMobile;
                this.endBubbleTranslate = config.translateEndingBubbleMobile
            }
            if (this.currBp == 'md' ){
                this.openingBubbleStartTime = config.startTimeOpeningBubbleTablet;
                this.openingBubbleEndTime = config.endTimeOpeningBubbleTablet;
                this.firstBubbleTranslate = `-${config.offsetSlideUpTablet}`;
            }
            if (this.currBp == 'lg' ){
                this.openingBubbleStartTime = config.startTimeOpeningBubbleLg;
                this.openingBubbleEndTime = config.endTimeOpeningBubbleLg;
            }

            if (this.currBp == 'xl' ){
                this.firstBubbleTranslate = `-${config.offsetSlideUpXlDsk}`;
                this.openingBubbleStartTime = config.startTimeOpeningBubbleXl;
                this.openingBubbleEndTime = config.endTimeOpeningBubbleXl;
                this.endBubbleTranslate = config.translateEndingBubbleXlDesktop
            }          
        },
        scrollTranslateBg(){
            registerPresets("scrollTranslateBg", {
                scrollTranslateBg: (sceneConfig) => {
                    let scrollTranslateBgAnim = animeJs.timeline({ autoplay: false, loop: true });

                    scrollTranslateBgAnim.add({
                        targets: sceneConfig.el,
                        easing: config.translateSectionEasing,
                        translateY: this.firstBubbleTranslate,
                    });
        
                    
                    sceneConfig.timelines.push({
                        start: this.openingBubbleStartTime,
                        end: this.openingBubbleEndTime,
                        timeline: scrollTranslateBgAnim,
                    });
        
                    return sceneConfig;
                },
            });

        },
        revealContent() {
            const bodyIsLoaded = document.querySelector('.loaded')
            // In the case where something above this section doesnt load or have a height before loaded ensure we dont init
            // bodyIsLoaded = Check if we have loaded class on body tag
            // window.scrollY = Page loaded in scrolled down if so we need to init
            if(bodyIsLoaded || window.scrollY > 0){
                let contentRevealTl = animeJs.timeline({
                    easing: config.revealContentEasing,
                    duration: 1600,
                });

                contentRevealTl
                    .add(
                        {
                            targets: ".animIntro__title",
                            opacity: 1,
                            translateX: 25,
                        },500)
                    .add({
                        targets: [".animIntro__subtitle"],
                        opacity: 1,
                    }, 700)
                    .add({
                        targets: [".animIntro__copy"],
                        translateY: -50,
                        opacity: 1,
                    }, 800)
                    .add({
                        targets: '.endingBubble',
                        translateY: this.endBubbleTranslate,
                        duration: config.revealEndingBubbleDuration
                    }, 1600);
                }
        }
    };
};

Alpine.data("animIntro", dataFN);
window.sui = window.sui || {};
window.sui.animIntro = dataFN;
