/**
 * Default page javascript. All other pages extend this one.
 */

// Polyfill
import arrayFindPolyfill from "./arrayFind";
import smoothscroll from "smoothscroll-polyfill";

// Libs
import whenDomReady from "when-dom-ready";
import "./lazysizes";
import Alpine from "alpinejs";
import mask from "@alpinejs/mask";
import alpineIntersect from "@alpinejs/intersect";
import * as DOMPurify from "dompurify";

import "../magics";

import siteConfig from "./siteConfig";
import "./sui/components";
import yupRanger from "./yupRanger";
import extend from "./extend";
import nav from "./nav";
import navSearch from "./navSearch";
import links from "./links";
import placeholder from "./form";
import carousel from "./carousel";
import "./stickyHeader";
import "./skipToMainContent";
import "./responsiveTables";
import sharePage from "./sharePage";
import user from "./user";
import gsa from "./gsa";
import storeListing from "./storeListing";
import browserHistory from "./browserHistory";
import { scrollToHash } from "./scrollTo";
import { addHtmlSupportClass } from "./isBrowserSupported";
import { trackEvent } from "./track";
import "./imageGallery";
import "./slider";
import "./testimonialSlider";
import "./animatedTextBlock";
import "./animatedTextSlider";
import "./comparator";

// CHC JS files/
import { removeUrlLastSlash, announcementFunc, anchorScrollToFunc, scrollToPos } from "./chc/helpers";
import carouselCHC from "./chc/carousel";
import noCardPeekCarousel from "./chc/noCardPeekCarousel";
import "./chc/searchAlgolia";
import "./chc/buttonAnchor";
import "./chc/buttonSticky";
import "./chc/stickyContentGrid";
import "./chc/header";
import "./chc/animatedBgOnScroll";
import lottieAnim from "./chc/lottieAnim";

import "../magics/chc/videoHelpers";

// Styles
import "../../css/pages/BasePage.scss";

window.Alpine = Alpine;
window.purify = DOMPurify;

class BasePage {
    constructor(...opts) {
        extend(this, opts);
        this.init();
    }

    init() {
        const self = this;
        self.ready = false;
        addHtmlSupportClass();
        self.onBeforeReady();

        whenDomReady().then(function () {
            self.onReady();
        });

        window.addEventListener("load", function () {
            self.onLoad();
        });
        smoothscroll.polyfill();

        Alpine.plugin(mask);
        Alpine.plugin(yupRanger);
        Alpine.plugin(alpineIntersect);

        Alpine.start();
    }

    onBeforeReady() {
        extend(this, siteConfig);
        arrayFindPolyfill();
    }

    onReady() {
        const self = this;
        self.ready = true;
        nav.init();
        navSearch.init();
        links.init();
        placeholder.init();
        carousel.init();

        sharePage.init();
        user.init();
        gsa.init();
        storeListing.init();
        browserHistory.init();
        // stickyHeader.init();

        // If page has error
        if (document.body.classList.contains("errorPage")) {
            this.pushPageErrorDatalayer();
        }

        // CHC Functions
        carouselCHC.init();
        noCardPeekCarousel.init();
        lottieAnim.init();

        removeUrlLastSlash();
        announcementFunc();
        anchorScrollToFunc();

        const windowHash = window.location.hash;
        if (windowHash) scrollToPos(windowHash);
    }

    onLoad() {
        document.getElementsByTagName("body")[0].classList.add("loaded");
        scrollToHash();
    }

    isReady() {
        var self = this;
        return self.ready === true;
    }

    pushPageErrorDatalayer() {
        trackEvent({
            event: "error_page",
            error_type: "Page Not Found",
            page_referrer: window.location.href, // Get Current Page URL
            interaction_type: "error_page",
        });
    }

    noop() {}
}

export default BasePage;
