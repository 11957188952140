/* eslint-disable */
import { debounce } from "./helpers/debounce";

document.addEventListener("alpine:init", () => {

    Alpine.data("comparator", (props) => {
        return {
            bpDesktop: 1366,
            bzvcLoaded: false,
            showMore: false,
            containerHeight: 0,
            hideContainerHeight: 0,
            init () {
                this.initFunc();
                this.setContainerHeight();     
                
                const debounceResize = debounce(() => this.initFunc(), 100);

                this.windowEvents(debounceResize);
            },
            initFunc() {
                this.setColWidth();
                this.noBazaarVoiceWidth();
                this.withBazaarVoiceWidth();
                this.setTopSticky();
            },
            windowEvents(debounceResize) {
                window.addEventListener("resize", debounceResize);
                window.addEventListener("scroll", () => this.setShadowHeader());
            },
            scrollToTop() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            removeZeroRating() {
                const _self = this.$el;
                const bzvcContent = _self.querySelectorAll(".comparator__detailInfo--bzvc");

                bzvcContent.forEach(el => {
                    const bzvc = el.querySelector(".bzvc");
                    const emptyRating = el.querySelector(".comparator__emptyRating");

                    if (!bzvc) {
                        emptyRating.classList.remove("hidden");
                        return;
                    }                        

                    const ratingEl = el.querySelector(".bv_averageRating_component_container");
                    const rating = ratingEl.querySelector("meta").getAttribute("content");

                    if (rating === "0.0") {
                        bzvc.classList.add("hidden");
                        emptyRating.classList.remove("hidden");
                    }
                });
            },
            getOffset(el) {
                const rect = el.getBoundingClientRect();
                return {
                  left: rect.left + window.scrollX,
                  top: rect.top + window.scrollY
                };
            },
            getStickyHeight() {
                const stickyHeader = document.querySelector("header[data-sticky='true']");
                const announcementBar = document.querySelector(".announcementBar.sticky");
                const announcementBarHeight = announcementBar? announcementBar.clientHeight : 0;

                return stickyHeader.offsetHeight + announcementBarHeight;
            },
            getLongest(items) {
                let longest = 0;
                
                items.forEach(item => {
                    const itemWidth = item.clientWidth;
                    longest = (itemWidth > longest)? itemWidth : longest;
                });
                
                return longest;
            },
            setColWidth() {    
                if (window.innerWidth >= this.bpDesktop) return;

                const itemLen = this.$refs.stickyHeader.querySelectorAll(".comparator__products").length;
                const divideNum = itemLen > 2? 3 : itemLen;
                const container = this.$el.querySelectorAll(".comparator__container");
                const title = this.$el.querySelectorAll(".comparator__detailTitle");

                const mainContainer = this.$el.querySelector(".comparator__main");
                const mainContainerWidth = mainContainer.clientWidth;
                const widthPerCol = mainContainerWidth / divideNum;

                container.forEach(el => el.style.minWidth = `${widthPerCol}px`);                
                title.forEach(el => el.style.minWidth = `${widthPerCol}px`);
            },
            setShadowHeader() {                
                const stickyHeader = this.$refs.stickyHeader;
                const { top } = this.getOffset(stickyHeader);
                const stickyHeight = this.getStickyHeight();
                
                if (window.innerWidth < this.bpDesktop) return;

                const scrollY = window.scrollY + stickyHeight;

                this.setClassEvent({ el: stickyHeader, bool: scrollY >= top, className: "shadowBottom" });
            },
            setClassEvent({ el, bool, className}) {
                const classEvent = bool? "add" : "remove";
                el.classList[classEvent](className);
            },
            setContainerHeight () {
                const container = this.$refs.container;
                const ifShowMore = container.classList.contains("comparator__border--showMore");

                if (!ifShowMore) return;

                this.containerHeight = container.clientHeight;

                setTimeout(() => {
                    this.hideContainerHeight = container.clientHeight;
                    this.setCss(container, { height: `${this.hideContainerHeight}px` });
                }, 100);
            },
            setCss(el, style) {
                Object.keys(style).forEach(key => {
                    el.style[key] = style[key];
                });
            },
            setWidth() { 
                const _self = this.$el;
                const container = this.$refs.stickyHeader;  
                const wrap = _self.querySelectorAll(".comparator__wrap");
                const containerItem = container.querySelectorAll(".comparator__container");

                const containerItemCount = containerItem.length;
                const containerItemWidth = this.getLongest(containerItem);
                const containerWidth = containerItemWidth * containerItemCount;

                wrap.forEach(el => this.setCss(el, { width: window.innerWidth < this.bpDesktop ? `${containerWidth}px` : "100%" }));
            },
            setTopSticky() {
                window.addEventListener("scroll", () => {
                    const breakPoint = window.innerWidth < this.bpDesktop;
                    const _self = this.$refs.stickyHeader;
                    let sumHeight = 0 ;

                    if (_self.classList.contains("sticky")) _self.style.top = sumHeight;

                    if (breakPoint) return;

                    const buttonSticky = document.querySelector(".buttonSticky--regular.sticky");
                    let buttonStickyrHeight = 0;

                    sumHeight = this.getStickyHeight();

                    if (_self.classList.contains("sticky")) _self.style.top = `${sumHeight}px`;

                    if (buttonSticky) {                 
                        if (breakPoint) return;

                        buttonStickyrHeight = buttonSticky.clientHeight;
                        const newSumHeight = sumHeight + buttonStickyrHeight;

                        if (_self.classList.contains("sticky")) _self.style.top = `${newSumHeight}px`;
                    }
                });
            },
            noBazaarVoiceWidth() {
                if (this.$refs.bzvc) return;

                let setWidth = 0;
                const _self = this.$el;
                const wrap = _self.querySelectorAll(".comparator__wrap");
                const stickyHeader = this.$refs.stickyHeader;
                const containers = stickyHeader.querySelectorAll(".comparator__container");
                
                containers.forEach(el => (setWidth += el.clientWidth));

                wrap.forEach(el => this.setCss(el, { width: window.innerWidth < this.bpDesktop ? `${setWidth}px` : "100%" }));
            },
            withBazaarVoiceWidth() {
                const bzvc = this.$refs.bzvc;

                if (!bzvc) {
                    this.bzvcLoaded = true;
                    return;
                }

                const bzvcEl = bzvc.querySelector(".bzvc");
                const config = { childList: true };

                const obs = new MutationObserver(mutationList => {
                    for (const mutation of mutationList) {
                        const [ childNode ] = mutation?.addedNodes;  

                        if (!this.bzvcLoaded) this.removeZeroRating();

                        this.bzvcLoaded = true;                 

                        setTimeout(() => this.setWidth(), 100);
                    }
                });
    
                obs.observe(bzvcEl, config);

                if (this.bzvcLoaded) this.setWidth();
            },
            showMoreLess() { 
                const container = this.$refs.container;

                this.showMore = !this.showMore;
                const setHeight = `${this.showMore? this.containerHeight : this.hideContainerHeight}px`;
                this.setCss(container, { height: setHeight });
            }
        };
    });

});