import { YTVidAutoPause } from "./chc/helpers";

let tns;
function init() {
    const carousels = document.querySelectorAll(".carousel");
    if (carousels.length > 0 && !carousels[0].classList.contains("carousel--editMode")) {
        import("tiny-slider/src/tiny-slider").then((module) => {
            tns = module.tns;
            carousels.forEach(function (carousel) {
                createCarousel(carousel);
            });
        });
    }
}

function createCarousel(carousel) {
    const carouselSlides = carousel.querySelector(".carousel__slides");
    if (carouselSlides) {
        const startIndex = carouselStartIndex(carousel);
        const carouselWrap = carousel.querySelector(".carousel__wrap");
        const dir = carouselWrap?.dataset.dir || "ltr";
        const lazyload = dir == "ltr"; // Use only TNS Lazyload when the content is LTR
        const carouselControls = carousel.querySelector(".carousel__controls");
        const autoplayButton = carousel.querySelector(".carousel__autoplayBtn");
        const itemsPerSlide = carouselWrap.dataset.itemsperslide;
        const itemsPerSlideTablet = carouselWrap.dataset.itemsperslidetablet;
        const itemsPerSlideDesktop = carouselWrap.dataset.itemsperslidedesktop;
        const autoplay = carouselWrap.dataset.autoplay == "true";
        const autoHeight = carouselWrap.dataset.autoheight == "true";
        const controls = carouselWrap.dataset.controls == "true";
        const pagination = carouselWrap.dataset.pagination == "true";
        const loop = carouselWrap.dataset.loop == "true";
        const uuid = carouselWrap.dataset.uuid;
        var tnsCarousel = tns({
            autoplay: autoplay,
            autoHeight: autoHeight,
            container: carouselSlides,
            controls: controls,
            controlsContainer: carouselControls,
            autoplayResetOnVisibility: false,
            items: itemsPerSlide,
            nav: pagination,
            navPosition: "bottom",
            startIndex: startIndex,
            loop: loop,
            autoplayButtonOutput: autoplay,
            autoplayText: ["<span class='carousel__playBtn carousel__btn'>▶</span>", "<span class='carousel__pauseBtn carousel__btn'>❚❚</span>"],
            autoplayButton: autoplay ? autoplayButton : false,
            arrowKeys: true,
            gutter: 20,
            lazyload: lazyload,
            responsive: {
                768: {
                    items: itemsPerSlideTablet,
                },
                1024: {
                    items: itemsPerSlideDesktop,
                },
            },
            onInit: function() {
                if (autoplay) {
                    const setVisuallyHiddenText = function (button, status) {
                        const visuallyHiddenText = button?.querySelector(".tns-visually-hidden");
                        if (visuallyHiddenText) {
                            const startText = button.dataset.starttext;
                            const stopText = button.dataset.stoptext;
                            if (status == "start") {
                                visuallyHiddenText.innerHTML = startText;
                            } else {
                                visuallyHiddenText.innerHTML = stopText;
                            }
                        }
                    };

                    setVisuallyHiddenText(autoplayButton, "stop");

                    autoplayButton?.addEventListener("click", function () {
                        setVisuallyHiddenText(this, this.dataset.action);
                    });
                }

                // Manipulate tns generated HTML
                carouselWrap.querySelector(".tns-liveregion").remove();
                removeTabIndex(carouselWrap);

                const carouselNav = carouselWrap.querySelector(".tns-nav");
                if (carouselNav) {
                    if (carouselNav.style.display != "none") {
                        carouselNav.classList.add("mx-auto");
                        // added padding-bottom when carousel nav is not hidden
                        carouselWrap.classList.add("carousel__wrap--hasPadding");

                        let carouselNavLabel = carouselWrap.dataset.paginationlabel;
                        carouselNav.setAttribute("aria-label", carouselNavLabel);
                        translatePageBullet(carouselWrap, carouselNav);
                    }

                    if (carouselControls) {
                        let controlsLabel = carouselWrap.dataset.controlslabel;
                        carouselControls.setAttribute("aria-label", controlsLabel);
                        carouselControls.after(carouselNav);
                    }
                }

            },
        });

        tnsCarousel.events.on("transitionEnd", function () {
            const carouselNav = carouselWrap.querySelector(".tns-nav");
            translatePageBullet(carouselWrap, carouselNav);
            removeTabIndex(carouselWrap);
        });


        tnsCarousel.events.on("transitionStart", function () {
            if (autoHeight && lazyload && dir == "ltr") { // Update active slide height when autoheight and lazyload is enabled                
                carouselWrap.querySelectorAll(".tns-slide-active img").forEach(function(image) {
                    image.addEventListener("load", function() {
                        tnsCarousel.updateSliderHeight(); 
                    });
                });
            }

            const carouselSlides = carouselWrap.querySelectorAll(".carousel__slide");
            YTVidAutoPause(carouselSlides);
        });

        if (dir == "rtl") {
            const callback = autoHeight ? () => {
                window.setTimeout(() => {
                    tnsCarousel.updateSliderHeight();
                }, 200);
            } : null;
            // Manually Lazyload the first index when content is RTL
            lazyloadSlide(tnsCarousel.getInfo(), callback);
            // Manual Lazyload on slide when content is RTL
            tnsCarousel.events.on("indexChanged", (event) => {
                lazyloadSlide(event, callback);
            });
        }

        if (!window.tnsCarousels) {
            window.tnsCarousels = [];
        }
        window.tnsCarousels.push({ uuid, carousel: tnsCarousel });
    }
}

function removeTabIndex(carouselWrap) {
    carouselWrap.querySelectorAll("[tabindex]").forEach(function (e) {
        e.removeAttribute("tabindex");
    });
}

function carouselStartIndex(carousel) {
    const carouselWrap = carousel.querySelector(".carousel__wrap");
    const dir = carouselWrap?.dataset.dir;

    let index = 0;
    if (dir == "rtl") {
        const slideLength = carousel.querySelectorAll(".carousel__slide").length;
        const loop = carouselWrap.dataset.loop == "true";

        if (loop) {
            const windowWidth = window.innerWidth;
            const desktopBreakPoint = 1024;
            const tabletBreakPoint = 768;

            let itemsPerSlide;
            if (windowWidth >= desktopBreakPoint) { // is desktop
                itemsPerSlide = carouselWrap.dataset.itemsperslidedesktop;                
            } else if (windowWidth >= tabletBreakPoint && windowWidth < desktopBreakPoint) { // is tablet
                itemsPerSlide = carouselWrap.dataset.itemsperslidetablet;
            } else { // is mobile
                itemsPerSlide = carouselWrap.dataset.itemsperslide;
            }

            index = Math.max(slideLength - itemsPerSlide, 0);
        } else {
            index = slideLength - 1;
        }
    }
    return index;
}

function translatePageBullet(carouselWrap, carouselNav) {
    let bulletLabel = carouselWrap.dataset.paginationbulletlabel;
    let activeBulletLabel = carouselWrap.dataset.paginationactivebulletlabel;
    if (carouselNav) {
        carouselNav.querySelectorAll("button").forEach(function (bullet, index) {
            let bulletAriaLabel = bulletLabel + " " + (index + 1);
            if (bullet.classList.contains("tns-nav-active")) {
                bulletAriaLabel = bulletAriaLabel + " (" + activeBulletLabel + ")";
            }
            bullet.setAttribute("aria-label", bulletAriaLabel);
        });
    }
}

function lazyloadSlide(event, callback) {
    // Manually Lazyload the images on active slides when content is RTL
    event.container.querySelectorAll(".tns-slide-active img:not(.lazyloaded)").forEach(image => {
        window.lazySizes.loader.unveil(image);
        image.classList.add("tns-complete");
        image.addEventListener("load", () => {
            if (callback) {
                callback();
            }
        });
    });
}

export default { init };