import whenDomReady from "when-dom-ready";
/* eslint-disable */

document.addEventListener("alpine:init", () => {
    Alpine.data("stickyContentGrid", () => ({
        init() {
            const _this = this;
            whenDomReady(function(){
                _this.getStickyHeaderHeight();
            });
        },
        resize() {
            this.getStickyHeaderHeight();
        },
        getStickyHeaderHeight() {
            const stickyHeader = document.querySelector("header.sticky nav"); // Get sticky header's height
            if (stickyHeader) {
                let offsetHeight = stickyHeader.offsetHeight;
                if (offsetHeight == 0) { // Header height is 0 when header communication is used
                    const headerComStickyHeader = document.querySelector("header.sticky > div"); // Use header child div to get header communication height
                    if (headerComStickyHeader) {
                        offsetHeight = headerComStickyHeader.offsetHeight;
                    }

                }
                const quicklinks = document.querySelector(".quicklinks");
                if(quicklinks){
                    offsetHeight = offsetHeight + 55 // Hardcoded Quicklinks height since it hides and shows hard to get offsetheight on load
                }
                    
                this.$el.style.top = offsetHeight + "px";
            }
        }
    }));
});
