import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";

import { searchBox, pagination, configure, sortBy } from "instantsearch.js/es/widgets";
import { connectRefinementList, connectClearRefinements, connectHits, connectInfiniteHits } from "instantsearch.js/es/connectors";
import { history } from "instantsearch.js/es/lib/routers";
import buildProductCard from "./algoProductCard";
import buildArticleCard from "./algoArticleCard";
import buildRefinementList from "./algoRefinementList";

/* eslint-disable */
document.addEventListener("alpine:init", () => {
    Alpine.data("algoliaSearchChc", (config) => {
        return {
            config: {
                /**
                 * Props are coming from light-modules/sanofi-lm-chc/templates/components/sanofi-chc/algolia/algoliaListing.ftl
                 */
                isAnonymous: "",
                algoliaAppId: "",
                algoliaAuthenticatedKey: "",
                algoliaIndexName: "",
                cssClassForm: "",
                cssClassInput: "",
                cssClassIcon: "",

                cssClassPaginationTextColor: "",
                cssClassPaginationHoverTextColor: "",
                noResultsMessage: "",
                showCategories: false,
                pagename: "",
                ...config,
            },

            
            

            widgetContainerPagination: ".as-pagination",

            renderResultsOnLoad: true,
            showMoreFn: null,
            isLastPage: false,
            showMoreBtnUsed: false,
            showNoResultsMsg: false,
            hasResults: false,
            isShowingFacets: true,
            instantsearch: null,

            facetsBuilt: false,
            valuesToRefineGlobal: [],

            updatedFilter: false,
            reInitFilter: "",

            totalResults: null,
            decodedCategories: null,
            checkedFacetCount: 0,
            refineListenerAttached: false,
            showRefinementList: true,
            
            hasCategories() {
                return this.showRefinementList;
            },
            fadeInElements(target) {
                const allElements = document.querySelectorAll(target);
                allElements.forEach((element) => {
                    const isHidden = element.classList.contains("opacity-0");
                    if (isHidden) {
                        element.classList.remove("opacity-0");
                        element.classList.add("opacity-1");
                    }
                });
            },
            showMoreOnIntersection() {
                if (!this.isLastPage && !this.showMoreBtnUsed) {
                    this.showMoreFn();
                }
            },
            clearActiveGroupCount() {
                this.config.facetsArray.forEach((item) => {
                    item.activeCount = 0;
                });

                this.updatePageMeta();
            },
            buildCards(item, instantsearch, config) {
                if (config.cardType == "article") {
                    return buildArticleCard(item, instantsearch, config);
                }
                return buildProductCard(item, instantsearch, config);
            },
            /**
             * Router Helpers
             *
             */
            getDecodedCategories() {
                this.config.decodedCategories = Object.keys(this.config.cleanSlugsMap).reduce((acc, key) => {
                    const newKey = this.config.cleanSlugsMap[key];
                    const newValue = key;

                    return {
                        ...acc,
                        [newKey]: newValue,
                    };
                }, {});
            },
            // Returns a slug from the category name.
            // Spaces are replaced by "+" to make
            // the URL easier to read and other
            // characters are encoded.
            getCategorySlug(name) {
                let encodedNames = [];
                name.forEach((element) => {
                    if (element != "") {
                        encodedNames.push(this.config.decodedCategories[element] || element);
                    }
                });

                return encodedNames.map(encodeURIComponent).join("+");
            },
            // Returns a name from the category slug.
            // The "+" are replaced by spaces and other
            // characters are decoded.
            getCategoryName(slug) {
                const slugs = slug.split("+");
                let decodedSlugPieces = []
                slugs.forEach((element) => {
                    if (element != "") {
                        element = element.replace(/\//g, ''); //Rm all slash in string
                        decodedSlugPieces.push(this.config.cleanSlugsMap[element] || element);
                    }
                });

                return decodedSlugPieces.map(decodeURIComponent);
            },

            breadcrumbs(categories, url){
                let breadcrumbsEl = document.querySelector('.breadcrumbs')
                const clonedItems = breadcrumbsEl.querySelectorAll('li[data-iscloned]');
                clonedItems.forEach(element => {
                    element.remove()
                });
                if(categories){
                    const cleanedCategories = categories.toString().replaceAll(',', ' + ')
                    const liItem = breadcrumbsEl.querySelector('li')
                    const new_element = liItem.cloneNode(true);
                    new_element.dataset.iscloned = true;
                    new_element.querySelector('span').classList.remove('underline');
                    new_element.querySelector('a').classList.add('flex-row-reverse')
                    new_element.querySelector('a').href = url
                    new_element.querySelector('span').innerHTML = cleanedCategories

                    breadcrumbsEl.querySelector('ul').append(new_element)
                    this.updatePageMeta(cleanedCategories, categories)
                }
            },
            updatePageMeta(cleanedCategories, categories) {
                const titleEl = document.querySelector('title');
                const metaTitleEl = document.querySelector('meta[name="title"]');
                const metaDescriptionEl = document.querySelector('meta[name="description"]');
                const categoryDescriptionEl = document.getElementById('category-description');
                const defaultTitle = this.config.defaultMetaData.defaultTitle || '';
                const defaultMetaTitle = this.config.defaultMetaData.defaultMetaTitle || '';
                const defaultMetaDescription = this.config.defaultMetaData.defaultMetaDescription || '';
            
                if (!cleanedCategories && !categories) {
                    if (titleEl) {
                        titleEl.textContent = defaultTitle;
                    }
                    if (metaTitleEl) {
                        metaTitleEl.setAttribute('content', defaultMetaTitle);
                    }
                    if (metaDescriptionEl) {
                        metaDescriptionEl.setAttribute('content', defaultMetaDescription);
                    }
                    if (categoryDescriptionEl) {
                        categoryDescriptionEl.textContent = '';
                    }
                    return;
                }

                const title = cleanedCategories;
                const metaTitles = [];
                const metaDescriptions = [];
                const categoryDescriptions = [];
            
                categories.forEach(category => {
                    this.config.facetsArray.forEach(facetGroup => {
                        const found = facetGroup.includedCategoriesActiveState.find((facet) => facet.title == category);
                        if (found) {
                            if (found.metaTitle !== undefined) {
                                metaTitles.push(found.metaTitle);
                            }
                            if (found.metaDescription !== undefined) {
                                metaDescriptions.push(found.metaDescription);
                            }
                            if (found.categoryDescription !== undefined && found.categoryDescription !== '') {
                                categoryDescriptions.push(found.categoryDescription);
                            }
                        }
                    });
                });

                if (titleEl && title !== '' || defaultTitle !== '') {
                    titleEl.textContent = title || defaultTitle;
                }
                
                if (metaTitleEl) {
                    metaTitleEl.setAttribute('content', metaTitles.length > 0 ? metaTitles.join(' + ') : defaultMetaTitle);
                }
                
                if (metaDescriptionEl) {
                    metaDescriptionEl.setAttribute('content', metaDescriptions.length > 0 ? metaDescriptions.join(' + ') : defaultMetaDescription);
                }

                if (categoryDescriptionEl) {
                    categoryDescriptionEl.textContent = categoryDescriptions.join(' + ');
                }            
            },
            /**
             * END, Router Helpers
             *
             */
            init() {
                const self = this;
                let algoliaClient = algoliasearch(this.config.algoliaAppId, this.config.algoliaAuthenticatedKey);
                
                self.useApplyButton = self.config.useApplyButton == "false" ? false : true;

                localStorage.setItem("indxName", this.config.algoliaIndexName);
                this.getDecodedCategories();

                const searchClient = {
                    ...algoliaClient,
                    search(requests) {
                        if (!self.renderResultsOnLoad) {
                            /**
                             * Do not render results on pageload
                             * https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/#detecting-empty-search-requests
                             */
                            if (requests.every(({ params }) => !params.query)) {
                                return Promise.resolve({
                                    results: requests.map(() => ({
                                        hits: [],
                                        nbHits: 0,
                                        nbPages: 0,
                                        page: 0,
                                        processingTimeMS: 0,
                                    })),
                                });
                            }
                        }

                        return algoliaClient.search(requests);
                    },
                };

                const algoliaIndexName = this.config.algoliaIndexName;

                let instantsearchProps = {
                    indexName: algoliaIndexName,
                    searchClient: searchClient,
                };

                /**
                 * Routing
                 * https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/js/#example-of-implementation
                 */

                instantsearchProps.routing = {
                    router: history({
                        createURL({ routeState, location }) {
                            let categoryPath = routeState.categories ? `${self.getCategorySlug(routeState.categories)}/` : "";
                            let cleanedPathName = location.pathname;
                            
                            cleanedPathName = cleanedPathName.split(self.config.pagename)[0];
                            cleanedPathName = `${cleanedPathName}${self.config.pagename}`;
                            cleanedPathName = cleanedPathName.replace(/\/$/, "");

                            if (categoryPath != "") {
                                // Only add / if we have a categoryPath
                                categoryPath = `/${categoryPath}`;
                                categoryPath = categoryPath.replace(/\/$/, "") //remove trailing slash
                            }

                            const url = `${location.origin}${cleanedPathName}${categoryPath}`

                            self.breadcrumbs(routeState.categories, url)

                            return url
                        },

                        parseURL({ location }) {
                            const pathnameMatches = location.pathname.split(self.config.pagename)
                            const category = self.getCategoryName((pathnameMatches && pathnameMatches[1]) || "");
                            
                            self.breadcrumbs(category, location.href)

                            return {
                                category,
                            };
                        },
                    }),

                    stateMapping: {
                        stateToRoute(uiState) {
                            const idxName = localStorage.getItem("indxName");
                            const indexUiState = uiState[idxName] || {};

                            return {
                                categories: indexUiState.refinementList && indexUiState.refinementList.categories,
                            };
                        },

                        routeToState(routeState) {
                            const idxName = localStorage.getItem("indxName");
                            return {
                                [idxName]: {
                                    refinementList: {
                                        categories: routeState.category,
                                    }
                                },
                            };
                        },
                    },
                };

                this.instantsearch = instantsearch(instantsearchProps);

                let configObj = {
                    attributesToSnippet: ["content:50", "description:50"],
                    hitsPerPage: parseInt(config.hitsPerPage, 10),
                };

                if (self.config.contentType != "all") {
                    configObj.filters = `pageType:${self.config.contentType}`;
                }

                const widgetList = [configure(configObj)];

                if (this.config.showInfiniteHits == "false") {
                    /**
                     * CONNECT HITS
                     * https://www.algolia.com/doc/api-reference/widgets/hits/js/#connector
                     */
                    // Create the render function
                    const renderHits = (renderOptions) => {
                        const { hits, widgetParams } = renderOptions;

                        widgetParams.container.innerHTML = `
                        ${hits
                            .map((item) => {
                                return this.buildCards(item, instantsearch, self.config);
                            })
                            .join("")}`;
                    };
                    // Create the custom widget
                    const customHits = connectHits(renderHits);

                    widgetList.push(
                        customHits({
                            container: document.querySelector("#hits"),
                        })
                    );

                    widgetList.push(
                        /**
                         * Pagination
                         * https://www.algolia.com/doc/api-reference/widgets/pagination/js/
                         */
                        pagination({
                            container: this.widgetContainerPagination,
                            cssClasses: {
                                root: "flex justify-center py-8 font-body",
                                list: "flex",
                                selectedItem: "font-bold",
                                disabledItem: "hidden",
                                link: `p-2 text-xl ${self.config?.cssClassPaginationTextColor} ${self.config?.cssClassPaginationHoverTextColor}`,
                            },
                        })
                    );
                }

                /**
                 * CONNECT INFINITE-HITS
                 * https://www.algolia.com/doc/api-reference/widgets/infinite-hits/js/#connector
                 */
                if (this.config.showInfiniteHits == "true") {
                    const renderInfiniteHits = (renderOptions, isFirstRender) => {
                        const { hits, widgetParams, showMore, isLastPage } = renderOptions;
                        const nextButton = document.getElementById("showmoreBtn");
                        if (isFirstRender) {
                            self.showMoreFn = showMore;
                            window.showMoreFnTest = showMore;
                            nextButton.addEventListener("click", () => {
                                self.showMoreBtnUsed = true;
                                showMore();
                            });

                            return;
                        }

                        if (isLastPage) {
                            nextButton.classList.add("hidden");
                        }

                        self.isLastPage = isLastPage;

                        widgetParams.container.innerHTML = `
                            ${hits
                                .map((item) => {
                                    return this.buildCards(item, instantsearch, self.config);
                                })
                                .join("")}
                        `;
                    };

                    const customInfiniteHits = connectInfiniteHits(renderInfiniteHits);

                    widgetList.push(
                        customInfiniteHits({
                            container: document.querySelector("#hits"),
                            showPrevious: false,
                        })
                    );
                }

                if (this.config.showCategories == "true") {
                    /**
                     * CONNECT REFINEMENT HITS - PREVENT AUTO QUERY
                     * https://discourse.algolia.com/t/stop-refinementlist-sorting-hits-upon-selection/8848
                     * https://codesandbox.io/p/sandbox/instantsearchjs-app-cety5
                     */
                    const renderRefinementList = (renderOptions) => {
                        const { items, refine } = renderOptions;
                        this.valuesToRefineGlobal = []
                        
                        const container = document.querySelector("#refinement-list");

                        container.innerHTML = "";
                        
                        buildRefinementList(items, container, self.config, self.config.facetsArray);

                        if (items.length == 0) {
                            // Show when no results - can do more here.. 
                            container.innerHTML = "Hit clear you have narrowed down to no results";
                        }

                        
                        document.getElementById("refine").addEventListener("click", (event) => {
                            event.preventDefault();
                            if(self.useApplyButton){
                                
                                this.valuesToRefineGlobal.forEach((value) => {
                                    refine(value);
                                });
        
                                this.valuesToRefineGlobal = [];
                            }
                        });
                    
                        

                        [...container.querySelectorAll(".refineItem")].forEach((element) => {
                            element.addEventListener("click", (event) => {
                                event.preventDefault();
                                const theInput = element.querySelector("input");
                                
                                let isChecked = theInput.hasAttribute("checked");
                                if (!isChecked) {
                                    element.classList.add('isChecked');
                                    theInput.setAttribute("checked", true);
                                    self.$dispatch('track-event', {
                                        'event': 'cmp_event',
                                        'event_type': 'click',
                                        'title': `filter applied - ${theInput.dataset.value}`,
                                    })
                                } else {
                                    element.classList.remove('isChecked');
                                    theInput.removeAttribute("checked");
                                    self.$dispatch('track-event', {
                                        'event': 'cmp_event',
                                        'event_type': 'click',
                                        'title': `filter removed - ${theInput.dataset.value}`,
                                    })
                                }

                                if (!this.valuesToRefineGlobal.includes(theInput.dataset.value)) {
                                    this.valuesToRefineGlobal.push(theInput.dataset.value);
                                } else {
                                    theInput.dataset.isRefined == "false";
                                    // See if its in Array
                                    let index = this.valuesToRefineGlobal.indexOf(theInput.dataset.value);
                                    if (index !== -1) {
                                        this.valuesToRefineGlobal.splice(index, 1);
                                    }
                                }

                                if(!self.useApplyButton){
                                    this.valuesToRefineGlobal.forEach((value) => {
                                        refine(value);
                                    });
                                }
                            });
                        });
                    };
                    // 2. Create the custom widget
                    const customRefinements = connectRefinementList(renderRefinementList);

                    widgetList.push(
                        customRefinements({
                            container: document.querySelector("#refinement-list"),
                            attribute: "categories",
                            operator: `${self.config?.refinementListFilterBy}`,
                            limit: 20,
                            searchableEscapeFacetValues: false,
                        })
                    );

                    /**
                     * Clear refinment list
                     * https://www.algolia.com/doc/api-reference/widgets/refinement-list/js/
                     */

                    let targetEl = ["clear-refinements-sidebar"];
                    if (this.config.showClearInHeader == "true") {
                        // Add Clear button in search header
                        targetEl.push("clear-refinements-header");
                    }

                    targetEl.forEach((element) => {
                        const renderClearRefinements = (renderOptions, isFirstRender) => {
                            const { refine } = renderOptions;
                            const container = document.querySelector(`#${element}`);

                            if (isFirstRender) {
                                const button = document.createElement("button");
                                button.textContent = self.config.clearFacetText;

                                button.addEventListener("click", () => {
                                    const facetItems = document.querySelectorAll(".facetItem");
                                    facetItems.forEach((item) => {
                                        item.style.fontWeight = "normal";
                                    });
                                    self.checkedFacetCount = 0;
                                    self.clearActiveGroupCount();
                                    refine();
                                });

                                container.appendChild(button);
                            }
                        };

                        const customClearRefinements = connectClearRefinements(renderClearRefinements);

                        widgetList.push(
                            customClearRefinements({
                                container: document.querySelector(`#${element}`),
                            })
                        );
                    });
                    0;
                }

                /**
                 * Sort By
                 * https://www.algolia.com/doc/api-reference/widgets/sort-by/js/
                 */
                if (this.config.showSortBy == "true") {
                    let sortIndexes = [{ label: this.config.defaultIndexLabel, value: this.config.algoliaIndexName }];

                    if (this.config.sortIndexes.length > 0) {
                        sortIndexes = [...sortIndexes, ...this.config.sortIndexes];
                    }

                    widgetList.push(
                        sortBy({
                            container: "#sort-by",
                            cssClasses: {
                                select: ["border rounded-xl p-4 w-full text-primary1 text-sm appearance-none"],
                            },
                            items: sortIndexes,
                        })
                    );
                }

                if (this.config.showSearchBox == "true") {
                    /**
                     * Searchbox
                     * https://www.algolia.com/doc/api-reference/widgets/search-box/js/
                     */
                    widgetList.push(
                        searchBox({
                            container: "#searchbox",
                            showReset: true,
                            showLoadingIndicator: false,
                            cssClasses: {
                                root: "pt-8",
                                form: `flex rounded-full shadow-xl ${self.config?.cssClassForm} w-12/12 mx-auto overflow-hidden`,
                                input: `h-12 md:h-20 lg:h-24 text-2xl w-full py-2 md:py-4 leading-tight ${self.config?.cssClassInput} focus:outline-none placeholder-black`,
                                submit: `order-first p-2 focus:outline-none w-24 flex items-center justify-center ${self.config?.cssClassIcon}`,
                                submitIcon: `h-6 w-6`,
                                reset: `order-last p-2 focus:outline-none w-24 flex items-center justify-center ${self.config?.cssClassIcon}`,
                                resetIcon: "h-6 w-6",
                            },
                            queryHook: (query, search) => {
                                // Clear categories refinement on a new search
                                if (this.hasCategories()) {
                                    this.instantsearch.helper.clearRefinements("categories");
                                }
                                search(query);
                            },
                        })
                    );
                }

                this.instantsearch.addWidgets(widgetList);

                this.instantsearch.start();

                this.instantsearch.on("render", () => {
                    const idxName = localStorage.getItem("indxName");
                    const renderState = this.instantsearch.renderState[idxName];
                    if (renderState) {
                        const hitsLength = this.config.showInfiniteHits == "true" ? renderState.infiniteHits.hits.length : renderState.hits.hits.length;
                        // Fade in hits
                        if(this.config.showInfiniteHits == "false"){
                            if (hitsLength > 0) {
                                self.showNoResultsMsg = false;
                                setTimeout(() => {
                                    
                                    this.fadeInElements("#hits li")
                                    this.fadeInElements(".bazaarvoiceWrap");
                                    this.fadeInElements(".search-header");
                                }, 400);
                            }
                        }

                        let toggledFacetCount = 0;
                        
                        renderState.refinementList?.categories?.items?.forEach((item) => {
                            if(this.config.hasSetFacetArrays){
                                // Keep Count of active items in a facet group
                                this.config.facetsArray.forEach(facetGroup => {
                                    const found = facetGroup.includedCategoriesActiveState.find((facet) => facet.title == item.value);
                                    if (found){
                                        if(!found.isActive && item.isRefined){
                                            found.isActive = true;
                                            facetGroup.activeCount++
                                        }
                                        if(found.isActive && !item.isRefined){
                                            found.isActive = false;
                                            if (facetGroup.activeCount > 0){
                                                facetGroup.activeCount--
                                            }
                                        }
                                    } 
                                });
                            }

                            // Keep Global Count
                            if (!item.isRefined) return;
                            toggledFacetCount++;
                        });
                        // Set total filter count
                        
                        this.checkedFacetCount = toggledFacetCount;
                        this.config.checkedFacetCount = toggledFacetCount; // Need this for when no facet groups are setup
                    }

                    if (this.instantsearch.helper.lastResults?.nbHits < 1) {
                        self.showNoResultsMsg = true;
                        this.fadeInElements(".search-header");
                    }

                    const noOfResults = this.instantsearch.helper.lastResults?.nbHits || 0;
                    this.totalResults = noOfResults;
                    this.hasResults = noOfResults > 0;
                });
            },
        };
    });
});
