import builMediaMarkUp from "./algoCardMedia";
import buildCategoriesMarkUp from "./algoProductCategories";

export default function buildArticleCard(item, instantsearch, config) {
    const mediaClasses = `w-full block h-[335px] lg:h-[416px] lazyload ${config.imageFill}`
    const mediaMarkUp = builMediaMarkUp(item, config, mediaClasses);
    const categoriesMarkup = buildCategoriesMarkUp(item, config, true);
    const listItemClass = config.showInfiniteHits == "true" ? "" : "opacity-0"
    
    let styleAttr = ``
    if(config.borderRadius){
        styleAttr = `style="border-radius: ${config.borderRadius}${config.borderRadiusUnit}"`
    }

    let imageOverlay = ``
    if(config.imageFilterOpacity !== '0'){
        imageOverlay = `<div
            class="article__imageOverlay absolute z-10 bottom-0 left-0 w-full h-[167px] lg:h-[208px] bg-gradient-to-t from-black"
            style="opacity: ${config.imageFilterOpacity}%"
        ></div>`
    }

    return `
    <li class="${listItemClass}">
        <div
            class="article overflow-hidden relative shadow-sm hover:shadow-lg pointer h-full bg-${config.backgroundColor}"
            ${styleAttr}
        >
        
            <div class="article__tags flex flex-wrap absolute z-10 top-6 left-0 px-6 text-xs #E5D489">
                ${categoriesMarkup}
            </div>

            <a
                href="${item.url}"
                alt="${item.title}"
            >
                <div class="article__imgWrapper relative bg-${config.imageBackgroundColor} overflow-hidden">
                    <div
                        class="uiImage relative block w-full article__img h-[335px] lg:h-[416px]"
                        data-imageren="true"
                    >
                        ${mediaMarkUp}
                    </div>
                    ${imageOverlay}
                </div>
                <div class="article__content px-6 pb-6 bg-${config.backgroundColor}">
                    <div class="article__title pt-3">
                        <span class="font-card text-${config.titleFontColor} ${config.titleFontSize}">
                            ${item.title}
                        </span>
                    </div>
                    <div class="article__description pt-2">
                        <div class="font-body rich-text leading-5">
                            <p>
                                <span class="${config.summaryFontSize} text-${config.summaryFontColor}">${item.description}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </li>
    `
}