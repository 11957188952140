/* eslint-disable */
import anime from "animejs/lib/anime.es.js";
import { breakpointIs } from "./breakpoint";
import { debounce } from "./helpers/debounce";

document.addEventListener("alpine:init", () => {
    Alpine.data("animatedTextBlock", (config) => ({
        background: null,
        text: null,
        hasPlayed: false,
        starting: false,
        animationPlaysContinuously: config.animationPlaysContinuously.toLowerCase() === "true",
        isMobileDelayDisabled: !breakpointIs("sm") && config.animationDisableMobileDelay.toLowerCase() === "true",

        init() {
            this.background = this.$refs.background;
            this.text = this.$refs.titleText;

            this.initIntersectionObserver();

            switch (config.trigger) {
                case "onPageLoad":
                    const loadDelay = this.isMobileDelayDisabled ? 0 : config.triggerDelay || 0;
                    setTimeout(() => this.start(), loadDelay);
                    break;
                case "afterDelay":
                    const afterDelay = this.isMobileDelayDisabled ? 0 : config.triggerDelay || 1000;
                    setTimeout(() => this.start(), afterDelay);
                    break;
            }

            window.addEventListener(
                "resize",
                debounce(() => this.handleResize())
            );
        },

        handleResize() {
            this.isMobileDelayDisabled = !breakpointIs("sm") && config.animationDisableMobileDelay.toLowerCase() === "true";
        },

        initIntersectionObserver() {
            const options = {
                root: null,
                rootMargin: "0px",
                threshold: 0,
            };
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        // Element exits the screen
                        if (this.animationPlaysContinuously) {
                            this.setInitialState();
                        }
                    }
                });
            }, options);

            observer.observe(this.background);
        },

        start() {
            if (this.hasPlayed && !this.animationPlaysContinuously) return;
            if (!this.starting) return;
            this.animateBackground();
        },

        animateBackground() {
            anime({
                targets: [this.background],
                translateX: ["-101%", "0%"],
                easing: "easeInOutSine",
                duration: config.animationDuration || 500,
                begin: () => {
                    this.text.classList.add(config.animationHighlightedTextColor);
                },
                complete: () => {
                    this.hasPlayed = true;
                    this.starting = false;
                },
            });
        },

        setInitialState() {
            anime.set(this.background, {
                translateX: "-101%",
            });
            this.text.classList.remove(config.animationHighlightedTextColor);
        },

        handleIntersect() {
            if (this.hasPlayed && !this.animationPlaysContinuously) return;
            this.starting = true
            if (config.trigger === "onPageScroll") {
                this.setInitialState();
                const delay = this.isMobileDelayDisabled ? 0 : config.triggerDelay || 0;
                setTimeout(() => this.start(), delay);
            }
        },
    }));
});
