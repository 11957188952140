/* eslint-disable */

import { Fancybox } from "@fancyapps/ui";
import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import { Thumbs } from "@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js";

document.addEventListener("alpine:init", () => {

    Alpine.data("imageGallery", (props) => {
        const state = {
            imageGalleryCarousel: null,
            ...props,
        };

        const carouselOptions = {
            infinite: false,
            Dots: {
                dynamicFrom: 3,
                dotTpl: '<button type="button" data-carousel-page="%i" aria-label="{{GOTO}}"><span class="block bg-white w-4 h-4 rounded-full border-gray-300 border-2 pointer-events-none" aria-hidden="true"></span></button>',
            },
            Navigation: {
                prevTpl: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 bg-white p-2 rounded-full" tabindex="-1"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" /></svg>',
                nextTpl: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 bg-white p-2 rounded-full" tabindex="-1"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" /></svg>',
            },
            Thumbs: {
                type: "classic",
                parentEl: document.getElementById(state.galleryId.id + '-thumbnails'),
            },
        };

        const fancyboxOptions = {
            Toolbar: {
                enabled: true,
                display: {
                    left: ["infobar"],
                    middle: ["zoomIn", "zoomOut", "toggle1to1"],
                    right: ["close"],
                },
            },
            Thumbs: {
                type: "classic",
            },
            tpl: {
                main: '<div class="fancybox__container" role="dialog" aria-modal="true" aria-label="{{MODAL}}" tabindex="-1"><div class="fancybox__backdrop"></div><div class="fancybox__carousel"><div x-data="{v:!0}" x-init="setTimeout(() => {v=!1;},2000)" x-show="v" class="flex items-center justify-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black text-white px-4 py-2 rounded-full bg-opacity-70"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" /></svg><span class="text-sm">Pinch to Zoom</span></div></div><div class="fancybox__footer"></div></div>',
            },
        }

        return {
            ...state,

            init() {
                this.initCarousel();
            },
            initCarousel() {
                const imageGallery = document.getElementById(state.galleryId.id);
                state.imageGalleryCarousel = new Carousel(imageGallery, carouselOptions, { Thumbs });
                !state.isEditMode ? this.initFancybox() : null;
            },
            initFancybox() {
                Fancybox.bind('[data-fancybox="' + state.galleryId.id + '"]', this.syncFancyboxOptions());
            },
            syncFancyboxOptions() {
                return {
                    ...fancyboxOptions,
                    Carousel: {
                        Sync: {
                            target: state.imageGalleryCarousel,
                        },
                    },
                };
            },
        };
    });

});