/* eslint-disable */
import { percentThroughView } from "../inView";

document.addEventListener('alpine:init', () => {
    Alpine.data('animatedBgOnScroll', (config) => ({
        offset: config.backgroundOffset,
        video: null,
        getVideo() {
            if (this.$breakpointIs("md")) {
                this.video = this.$el.querySelector(".animationVideo")
            } else {
                this.video = this.$el.querySelector(".animationVideo.mobileVideo") || this.$el.querySelector(".animationVideo")
            } 
        },
        async scrollEvent() {
            window.onscroll = async() => {
                this.getVideo()
                let duration = this.video.duration;
                let currView = percentThroughView(this.video);

                if (currView > 1 || currView < 0) return;

                 // Wait for the video to load metadata
                await new Promise(resolve => {
                    if (this.video.readyState >= 2) {
                        resolve();
                    } else {
                        this.video.onloadedmetadata = () => resolve();
                    }
                });
                const offset = (((this.offset/100) * currView) * 2) + currView
                const currentFrame =  duration * offset;
                this.video.pause();
                this.video.currentTime = currentFrame.toFixed(2);
            }
        }
    })) 
})