/* eslint-disable */

import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import { Autoplay } from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js";

document.addEventListener("alpine:init", () => {

    Alpine.data("testimonialSlider", (props) => {
        const state = {
            slider: null,
            ...props,
        };

        const sliderOptions = {
            Autoplay : {
                timeout : 1e4,
                showProgress: false
            },
            Dots: {
                minCount: 2,
              },
            infinite: true,
        };

        return {
            ...state,
            ratingShow: false,

            init() {
                const sliderElm = document.getElementById(state.sliderId);
                if (!state.isEditMode) {
                    state.slider = new Carousel(sliderElm, sliderOptions, { Autoplay });
                }
            },    
            setStarRating() {
                const ratingNum = Number(this.$el.dataset?.rate);
                const ratingNoDecimal = Math.floor(ratingNum);
                const getDecimal = Math.ceil(ratingNum % 1);
                const zeroRating = 5 - (ratingNoDecimal + getDecimal);
    
                this.$el.dataset.fullStar = ratingNoDecimal;
                this.$el.dataset.halfStar = getDecimal;
                this.$el.dataset.zeroStar = zeroRating;
            },
            checkIfZero(num) {
                return Number(num) > 0;
            }
        };
    });

});