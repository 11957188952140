import Alpine from "alpinejs";
import { animeJs, registerPresets } from "@crescendo-collective/anim-scroll";
import { currentBreakpoint } from "../../breakpoint";

document.addEventListener("alpine:init", () => {
    Alpine.data("animSvg", (config) => ({
        init() {
            this.setConfigOnBp();
            this.scaleBg();
        },
        revealContent(){
            let bodyTag = document.getElementsByTagName('body')
            let hasLoaded = bodyTag[0].classList.contains("loaded")
            
            if(hasLoaded){
                let contentRevealTl = animeJs.timeline({
                    easing: config.revealContentEasing,
                    duration: config.revealContentDuration,
                    delay: 200
                });
    
                contentRevealTl
                    .add({
                        targets: [".textWrap"],
                        translateY: -50,
                        opacity: 1,
                    });
            }
        },
        listenForWindowResize() {
            const self = this;
            let isResizing;
            window.addEventListener("resize", function () {
                window.clearTimeout(isResizing);
                isResizing = setTimeout(function () {
                    self.setConfigOnBp()
                }, 500);
            });
        },
        setConfigOnBp(){
            this.currBp = currentBreakpoint();
            
            this.selectScaleFrom = config.scaleBgFrom
            this.selectScaleTo = config.scaleBgTo
            if (this.currBp == 'sm' || this.currBp == 'xs'){
                this.selectScaleFrom = config.scaleBgFromMobile
                this.selectScaleTo = config.scaleBgToMobile
            }
        },
        scaleBg(){
            registerPresets("scaleSvgAnim", {
                scaleSvgAnim: (sceneConfig) => {
                    let scaleSvgAnimAnim = animeJs.timeline({ autoplay: false, loop: true });
        
                    animeJs.set(sceneConfig.el, {
                        scale: this.selectScaleFrom,
                        translateY: 100
                    });
        
                    scaleSvgAnimAnim.add({
                        targets: sceneConfig.el,
                        easing: config.scaleBgEasing,
                        scale: this.selectScaleTo,
                        translateY: 0
                    });
        
                    sceneConfig.timelines.push({
                        start: config.scaleBgStart,
                        end: config.scaleBgEnd,
                        timeline: scaleSvgAnimAnim,
                    });
        
                    return sceneConfig;
                },
            });
        },
    }));
});