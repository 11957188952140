function newPagination(carouselWrap, tnsCarousel) {
    const info = tnsCarousel.getInfo();
    const itemCount = info?.slideCount;
    const newPagination = carouselWrap.querySelector(".carousel__simplifiedPagination");
    const perSlide = getPerSlide(carouselWrap);

    const numberPagination = {
        mobile: Math.ceil(itemCount / perSlide?.mobile),
        tablet:  Math.ceil(itemCount / perSlide?.tablet),
        desktop: Math.ceil(itemCount / perSlide?.desktop)
    };

    if (newPagination) {
        carouselWrap.classList.add("carousel__wrap--hasPadding");
        createNewPagination(tnsCarousel, newPagination, numberPagination);

        tnsCarousel.events.on('newBreakpointStart', () => {
            createNewPagination(tnsCarousel, newPagination, numberPagination);
        })
    }
}

function getPerSlide(carouselWrap) {
    const itemsPerSlide = carouselWrap.dataset.itemsperslide;
    const itemsPerSlideTablet = carouselWrap.dataset.itemsperslidetablet;
    const itemsPerSlideDesktop = carouselWrap.dataset.itemsperslidedesktop;

    let perSlide = {
        mobile: itemsPerSlide,
        tablet:  itemsPerSlideTablet,
        desktop: itemsPerSlideDesktop
    }
    
    return perSlide;
}


function createNewPagination(tnsCarousel, newPagination, numberPagination) {
    let countDots = 0;
    const wWidth = window.innerWidth;

    if (wWidth > 300 && wWidth < 768) countDots = numberPagination.mobile
    else if (wWidth > 767 && wWidth < 1024) countDots = numberPagination.tablet
    else countDots = numberPagination.desktop

    if (countDots > 1) {
        newPagination.innerHTML = "";
        for (let key = 0; key < countDots; key++) {
            if (key < 3) {
                const page = document.createElement("button");
                newPagination.appendChild(page);
            }
        }

        changeActive(newPagination, tnsCarousel);
        tnsCarousel.events.on('indexChanged', () => {    
            changeActive(newPagination, tnsCarousel);
        })
    }
}

function changeActive(newPagination, tnsCarousel) {
    const carouselWrap = newPagination.parentNode;
    const info = tnsCarousel.getInfo();
    const dots = newPagination.querySelectorAll("button");
    const wWidth = window.innerWidth;
    const perSlide = getPerSlide(carouselWrap);

    dots.forEach(el => el.classList.remove("tns-nav-active"));

    let itemPerPage = 1;
    if (wWidth > 300 && wWidth < 768) itemPerPage = perSlide.mobile
    else if (wWidth > 767 && wWidth < 1024) itemPerPage = perSlide.tablet
    else itemPerPage = perSlide.desktop
    
    const maxSlide = carouselWrap.dataset.loop === "true"? info?.slideCount : (info?.slideCount + 1) - Number(itemPerPage);
    const currSlide = info?.displayIndex;

    const firstDot = dots[0];
    const middleDot = dots[1];
    const lastDot = dots[dots.length - 1];

    if (currSlide === 1) {
        firstDot.classList.add("tns-nav-active");
    }
    else if (currSlide === maxSlide) {
        lastDot.classList.add("tns-nav-active");
    }
    else {
        if (dots.length > 2) dots[1].classList.add("tns-nav-active");
        else firstDot.classList.add("tns-nav-active");
    }

    firstDot.onclick = () => {
        tnsCarousel.goTo("first");
    };

    lastDot.onclick = () => {
        tnsCarousel.goTo(maxSlide);
    }

    if (dots.length > 2) {
        middleDot.onclick = () => {
            const maxDivide2 = (info?.slideCount / 2) - 1;
            
            if (maxSlide > 3) tnsCarousel.goTo(Math.ceil(maxDivide2));
            else tnsCarousel.goTo(1);
        };
    }
}

export default { newPagination }