import carousel3DotsPagination from "./carousel3DotsPagination";

function init() {
    const carousels = document.querySelectorAll(".carousel");
    if (carousels.length > 0 && !carousels[0].classList.contains("carousel--editMode")) {
        import("tiny-slider/src/tiny-slider").then(() => {
            carousels.forEach(async function (carousel) {
                const res = await fetchTNS(carousel);
                if (res) {
                    renderNewPagination(carousel);
                }
            });
        });
    }
}

function renderNewPagination(carousel) {
    const carouselWrap = carousel.querySelector(".carousel__wrap");
    const uuid = carouselWrap.dataset.uuid;
    const getCarousel = window.tnsCarousels;

    getCarousel.forEach(item => {
        if (item.uuid === uuid) {
            carousel3DotsPagination.newPagination(carouselWrap, item.carousel);
        }
    });
}

function fetchTNS(carousel) {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (carousel.querySelector(".tns-inner")) {
                resolve(carousel.querySelector(".tns-inner"));
                clearInterval(interval);
            }
        }, 100)
    });
}

export default { init };