/* eslint-disable */
import { scrollToPos } from "./helpers";


document.addEventListener("alpine:init", () => {

    Alpine.data("buttonSticky", () => ({
        config: {
            iterations: 1,
            duration: 300
        },
        duration: 290,
        buttonShrinkWidth: "80px",
        buttonWidth: 0,
        loadDone: false,
        init() {
            const buttonSticky = this.$refs.buttonStickyElem;
            this.buttonWidth = buttonSticky.clientWidth;

            if (buttonSticky) {
                const { delay, scroll, state } = buttonSticky?.dataset;
                    
                if (state === "collapsed") this.autoCollapsed(buttonSticky);                 

                window.onload = () => {
                    this.loadDone = true;
                    
                    if (delay) this.timeDelay();
                    if (scroll === "true") this.scrollHide();
                };

            }
        },
        elems() {
            const buttonSticky = this.$refs.buttonStickyElem;
            
            if (!buttonSticky) return false;

            const description = buttonSticky.querySelector(".buttonSticky__text");
    
            return {
                buttonSticky,
                description
            };
        },
        hideAnimation(button = {}) {
            if (!button?.buttonSticky) return false;
    
            const { buttonSticky, description } = button;
    
            if (buttonSticky.classList.contains("buttonSticky--animated")) return false;
    
            buttonSticky.classList.replace("buttonSticky--animate", "buttonSticky--animated");
    
            buttonSticky.animate([
                { width: `${this.buttonWidth}px` },
                { width: this.buttonShrinkWidth }
            ], this.config);
    
            description.animate([
                { opacity: 1 },
                { opacity: 0 }
            ], this.config);
    
            setTimeout(() => this.multiAddClass([buttonSticky, description], ["w-20", "hidden"]), this.duration);
        },
        timeDelay() {
            const timeDelay = this.elems().buttonSticky?.dataset.delay;
            setTimeout(() => {
                this.hideAnimation(this.elems());
            }, timeDelay)
        },
        scrollHide() {
            window.onscroll = () => {
                if (window.scrollY > 10) {
                    this.hideAnimation(this.elems());
                }
            }
        },
        autoCollapsed(elem) {
            const buttonSticky = elem;
            const description = buttonSticky.querySelector(".buttonSticky__text");
            this.multiAddClass([buttonSticky, description], ["w-20", "hidden"]);
        },
        multiAddClass(elem= [], classes= []) {
            elem.forEach((el, key) => el.classList.add(classes[key]))
        },
        scrollToFromPlatform(id) {
            scrollToPos(id);
            setTimeout(() => {
                const buttonSticky = this.$refs.buttonStickyElem;
            
                const mouseLeaveEvent = new MouseEvent('mouseleave', {
                    bubbles: true,
                    cancelable: true
                });
            
                buttonSticky.dispatchEvent(mouseLeaveEvent);
            }, 3000);            
        },
        mouseEnter() {            
            const buttonSticky = this.$el;
            const animated = buttonSticky.classList.contains("buttonSticky--animated");
            const hovered = buttonSticky.classList.contains("buttonSticky--hovered");
            const description = buttonSticky.querySelector(".buttonSticky__text");
            
            if (!hovered && this.loadDone) {    
                description.classList.remove("hidden");
                buttonSticky.classList.add("buttonSticky--hovered");

                if (animated) {
                    buttonSticky.animate([
                        { width: this.buttonShrinkWidth },
                        { width: `${this.buttonWidth}px` }
                    ], this.config);
    
                    description.animate([
                        { opacity: 0 },
                        { opacity: 1 }
                    ], this.config);
    
                    setTimeout(() => buttonSticky.classList.remove("w-20"), this.duration);
                } else {
                    buttonSticky.classList.replace("buttonSticky--animate", "buttonSticky--animated");
                }                
                
            }
        },
        mouseLeave() {
            const buttonSticky = this.$el;
            const animated = buttonSticky.classList.contains("buttonSticky--animated");
            const hovered = buttonSticky.classList.contains("buttonSticky--hovered");
            const description = buttonSticky.querySelector(".buttonSticky__text");

            if (animated && hovered && this.loadDone) {
                buttonSticky.classList.remove("buttonSticky--hovered");

                buttonSticky.animate([
                    { width: `${this.buttonWidth}px` },
                    { width: this.buttonShrinkWidth }
                ], this.config);

                description.animate([
                    { opacity: 1 },
                    { opacity: 0 }
                ], this.config);
        
                setTimeout(() => this.multiAddClass([buttonSticky, description], ["w-20", "hidden"]), this.duration);
            }
        },
        pageRedirect() {
            const { href, target } = this.$el;
            
            if (window.innerWidth < 1025) setTimeout(() => window.open(href, target), 1e3);
            else window.open(href, target);
        }
    }));

});
