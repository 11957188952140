/* eslint-disable */
import anime from "animejs/lib/anime.es.js";
import { debounce } from "./helpers/debounce";
import { breakpointIs } from "./breakpoint";
import deviceCheck from "./deviceCheck";

document.addEventListener("alpine:init", () => {
    Alpine.data("animatedTextSlider", (config) => ({
        activeSlide: 0,
        slides: [],
        interval: null,
        isStarted: false,
        isPaused: false,
        isFixedHeight: true,
        observer: null,
        transformDistance: 15,
        pauseOnHover: false,
        isTransitioning: false,
        resetOnExit: false,
        isMobileDelayDisabled: !breakpointIs("sm") && config.animationDisableMobileDelay.toLowerCase() === "true",
        ...config,

        init() {
            this.pauseOnHover = config.pauseOnHover; // Used in template
            this.resetOnExit = config.resetOnExit === "true";
            this.slides = this.$refs.slider.querySelectorAll(".animated-text-slide");
            this.triggerDelay = parseInt(config.triggerDelay);
            this.entryEffectSpeed = parseInt(config.entryEffectSpeed);
            this.transitionEffectSpeed = parseInt(config.transitionEffectSpeed);
            this.transitionEffectPauseTime = parseInt(config.transitionEffectPauseTime);
            this.transitionDelay = parseInt(config.transitionDelay);

            if (config.isEditMode == "false") {
                this.initResizeHandler();
                this.initVisibiltyHandler();
                this.initIntersectionObserver();

                this.slides.forEach((slide, index) => {
                    // Allow overflow for single slide
                    if (this.slides.length === 1) {
                        this.$refs.slider.style.overflow = "initial";
                    }

                    slide.style.display = "none"; // Initially hide all slides

                    slide.setAttribute("aria-hidden", "true");
                    slide.setAttribute("aria-label", `Slide ${index + 1} of ${this.slides.length}`);
                });

                // Handle different initialization triggers
                switch (config.trigger) {
                    case "onPageLoad":
                        this.start();
                        break;
                    case "afterDelay":
                        setTimeout(() => {
                            this.start();
                        }, this.triggerDelay);
                        break;
                }
            }
        },

        initResizeHandler() {
            this.setFixedHeight(true);

            const debouncedResize = debounce(() => {
                this.isMobileDelayDisabled = !breakpointIs("sm") && config.animationDisableMobileDelay.toLowerCase() === "true";
                if (deviceCheck.getDeviceType() == "mobile") return;
                this.setFixedHeight();
            }, 100);

            window.addEventListener("resize", debouncedResize);
        },

        initVisibiltyHandler() {
            document.addEventListener("visibilitychange", () => this.handleVisibilityChange());
        },

        initIntersectionObserver() {
            const options = {
                root: null,
                rootMargin: "0px",
                threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (!this.resetOnExit) return;

                    const { intersectionRatio } = entry;
                    const isFullyVisible = intersectionRatio === 1;

                    if (isFullyVisible) {
                        if (!this.isStarted) {
                            this.start();
                        }
                    } else {
                        this.resetSlider(); // This resets the slider for any partial visibility including no visibility
                    }
                });
            }, options);

            observer.observe(this.$refs.slider);
        },

        resetSlider() {
            // Stop the current interval
            clearInterval(this.interval);
            this.interval = null;

            // Reset to the first slide
            this.activeSlide = 0;

            // Hide all slides
            this.slides.forEach((slide) => {
                slide.style.display = "none";
                slide.style.opacity = "0";
                slide.style.transform = "translate(0, 0)";
                slide.setAttribute("aria-hidden", "true");
            });

            // Reset animation-related states
            this.isStarted = false;
            this.isPaused = false;
            this.isTransitioning = false;
        },

        setFixedHeight(isInit = false) {
            requestAnimationFrame(() => {
                let maxHeight = 0;
                this.slides.forEach((slide) => {
                    slide.style.display = "block";
                    maxHeight = Math.max(maxHeight, slide.scrollHeight);
                    if (this.slides.length > 1 || isInit) {
                        slide.style.display = "none";
                    }
                });

                // Add padding to the maxHeight for proper height
                const sliderStyles = window.getComputedStyle(this.$refs.slider);
                const sliderPaddingTop = parseFloat(sliderStyles.paddingTop);
                const sliderPaddingBottom = parseFloat(sliderStyles.paddingBottom);
                const totalHeight = maxHeight + sliderPaddingTop + sliderPaddingBottom;

                this.$refs.slider.style.height = `${totalHeight}px`;
            });
        },

        start() {
            if (config.isEditMode == "false") {
                this.isStarted = true;
                this.transitionSlides(0, 0); // Immediately animate the first slide
                if (!this.interval) {
                    this.interval = setInterval(() => {
                        if (!this.isPaused) {
                            this.nextSlide();
                        }
                    }, this.transitionEffectPauseTime + this.transitionDelay);
                }
            }
        },

        nextSlide() {
            if (this.isTransitioning) return;
            const oldSlide = this.activeSlide;
            this.activeSlide = (this.activeSlide + 1) % this.slides.length;

            const playsContinuously = config.playsContinuously.toLowerCase() === "true";

            // If not playing continuously and it's the last slide, clear the interval and do not transition
            if (!playsContinuously && this.activeSlide === 0) {
                clearInterval(this.interval);
                this.interval = null;
                return;
            }

            // Add a delay before transitioning to the next slide
            this.transitionSlides(oldSlide, this.activeSlide);
        },

        transitionSlides(oldSlideIndex, newSlideIndex) {
            if (this.isTransitioning) return;
            this.isTransitioning = true;

            // Cancel ongoing animations
            anime.remove([this.slides[oldSlideIndex], this.slides[newSlideIndex]]);

            const exitEffectSettings = this.getAnimationSettings(config.transitionEffect, config.transitionEffectDirection, this.transitionEffectSpeed, true);
            const entryEffectSettings = this.getAnimationSettings(config.entryEffect, config.entryEffectDirection, this.entryEffectSpeed);

            this.slides[oldSlideIndex].setAttribute("aria-hidden", "true");
            this.slides[newSlideIndex].setAttribute("aria-hidden", "false");

            // Animate out the current slide & prepare the next slide
            anime({
                targets: this.slides[oldSlideIndex],
                ...exitEffectSettings,
                begin: () => {
                    if (config.entryEffect === "wordByWord") {
                        this.wordByWord(this.slides[this.activeSlide].querySelector(".animated-text-slide__title"));
                    }
                },
                complete: () => {
                    this.slides[oldSlideIndex].style.display = "none";
                    const delay = this.isMobileDelayDisabled ? 0 : this.transitionDelay;
                    // Delay between next slide appearing
                    setTimeout(() => {
                        this.slides[newSlideIndex].style.display = "block";

                        anime({
                            targets: this.slides[newSlideIndex],
                            ...entryEffectSettings,
                            complete: () => {
                                this.isTransitioning = false;
                            },
                        });
                    }, delay);
                },
            });
        },

        wordByWord(element) {
            // Function to recursively wrap text nodes in spans
            function wrapTextNodes(node, isRoot = true) {
                if (node.nodeType === Node.TEXT_NODE) {
                    return node.textContent
                        .split(/\s+/)
                        .map((word) => {
                            return word.trim() ? `<span style="display: inline-block; opacity: 0;">${word}</span>` : "";
                        })
                        .join(" ");
                } else if (node.nodeType === Node.ELEMENT_NODE) {
                    let childrenHtml = Array.from(node.childNodes)
                        .map((child) => wrapTextNodes(child, false))
                        .join("");

                    if (isRoot) {
                        return childrenHtml;
                    } else {
                        let newNode = node.cloneNode(false);
                        newNode.innerHTML = childrenHtml;
                        return newNode.outerHTML;
                    }
                }
                return "";
            }

            // Wrap text nodes while preserving the formatting
            if (!element.dataset.transformed) {
                element.innerHTML = wrapTextNodes(element);
                element.dataset.transformed = "true";
            }

            const words = element.querySelectorAll("span");

            // Create a new timeline with default easing and duration
            var tl = anime.timeline({
                easing: "easeOutExpo",
                duration: this.entryEffectSpeed,
            });

            words.forEach((span, index) => {
                // Calculate the staggered start time for each word's animation
                // Formula: (index + 1) * (pauseTime / (wordCount + 2)) + entryEffectSpeed
                //
                // - index + 1: Ensures the first word doesn't start immediately (at time 0)
                // - pauseTime / (wordCount + 2):
                //   * Divides the pause time into equal parts for each word, plus two extra parts:
                //     one for initial delay and one for final pause
                //   * This creates an evenly-spaced animation where words appear one after another
                // - entryEffectSpeed: Adds a delay equal to the entry effect duration before starting word animations
                //
                // Result: A smooth, staggered animation with:
                // 1. Initial pause (entryEffectSpeed)
                // 2. Words appearing one by one
                // 3. Final pause before the next slide transition
                const timeOffset = Math.round((index + 1) * (this.transitionEffectPauseTime / words.length + 2) + this.entryEffectSpeed);
                tl.add(
                    {
                        targets: span,
                        opacity: [0, 1],
                        translateY: [-this.transformDistance, 0],
                    },
                    timeOffset
                );
            });
        },

        getAnimationSettings(effect, direction, speed, isExit = false) {
            const effectSettings = {
                easing: "easeInOutSine",
                duration: parseInt(speed) || 1000, // Default duration is set to 1000ms
            };

            let properties = {
                translateX: [0, 0],
                translateY: [0, 0],
                opacity: isExit ? [1, 0] : [0, 1],
            };

            if (effect === "slide") {
                switch (direction) {
                    case "left":
                        properties.translateX = isExit ? [0, -this.transformDistance] : [-this.transformDistance, 0];
                        break;
                    case "right":
                        properties.translateX = isExit ? [0, this.transformDistance] : [this.transformDistance, 0];
                        break;
                    case "top":
                        properties.translateY = isExit ? [0, -this.transformDistance] : [-this.transformDistance, 0];
                        break;
                    case "bottom":
                        properties.translateY = isExit ? [0, this.transformDistance] : [this.transformDistance, 0];
                        break;
                    default:
                        properties.translateX = isExit ? [0, -this.transformDistance] : [-this.transformDistance, 0];
                        break;
                }
            }

            return { ...effectSettings, ...properties };
        },

        pause() {
            this.isPaused = true;
        },

        play() {
            if (this.isPaused) {
                this.isPaused = false;
            }
        },

        handleIntersect() {
            if (this.isStarted) return;
            if (config.trigger === "onScrollView") {
                this.start();
            }
        },

        handleVisibilityChange() {
            if (document.hidden) {
                this.pause();
            } else {
                this.play();
            }
        },
    }));
});
