export default function buildBVMarkUp(item, config) {
    let bvRating = ``;
    const isFadeIn = config.showInfiniteHits == "true" ? "" : "opacity-0"
    if (item.bzvProductId && config.bazaarVoiceLink && config.showBzvReviews == 'true'){
        bvRating = `<div class="py-2 min-h-[45px]">
            <div x-data="" 
                x-init="$loadScript('${config.bazaarVoiceLink}')" 
                data-bv-show="inline_rating" 
                data-bv-product-id="${item.bzvProductId}" 
                data-bv-redirect-url="${item.url}#review_section"
                class="bazaarvoiceWrap overflow-hidden transition-all ${isFadeIn}">
            </div>
        </div>
        `
    }

    return bvRating
}